import React,{useState}from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './Login.css'
import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';


function ForgotPassword() {
  const Navigate = useNavigate()
  const [email, setemail] = useState()
  const [username, setusername] = useState()
  const [loading, setLoading] = useState(false);




const Forgetpass = async()=>{
  setLoading(true)
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var raw = JSON.stringify({
  "email": email,
  "username": username,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/forgot/password`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if(result.status == '1'){
      toast(result.msg)
      Navigate('/home');
      setemail('')
      setusername('')

    }
   })
  .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
}



  return (
  
<>
<div>
  <ToastContainer/>
</div>
<div class="wrapper">

<div class="login-box">
  <div>
    <h2>Forgot Password?</h2>

    <div class="input-box">
      <span class="icon">
        <ion-icon name="mail"></ion-icon>
      </span>
      <input type="email" value={email} onChange={(e)=>{setemail(e.target.value)}} placeholder='Enter Email' required/>
     
    </div>

    <div class="input-box">
      <span class="icon">
        <ion-icon name="lock-closed"></ion-icon>
      </span>
      <input type="username" value={username} onChange={(e)=>{setusername(e.target.value)}} placeholder='Enter Usename' required/>

    </div>  
    <button onClick={Forgetpass }>
              {loading ? 'Loading...' : 'Procced'}
</button>
  </div>
</div>

</div>
</>
  )
}

export default ForgotPassword
