import React from 'react'
import { NavLink,Link } from 'react-router-dom';
import '../src/Sidebar.css'
import { MdOutlineLegendToggle } from "react-icons/md";
import logo from '../src/Img/logo.png'
import { Container, Offcanvas, Navbar, Nav, Image } from "react-bootstrap";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {BiDetail} from 'react-icons/bi';
import { FaBitcoin } from "react-icons/fa";
import { LuServer } from "react-icons/lu";

function SideBar() {
  const Navigate = useNavigate();

  const offsetValue = -56;
  const handleLogout = () => {
    // Remove Token from Local Storage
    localStorage.removeItem('token'); 
    // Redirect to login page
    Navigate('/')

    window.location.reload()
  };


  return (
    <div>
      <div className='container-fluid desktop'>
        <div className='row'>
          <div className='col-lg-12 col-sm-12    Helo ' >
            <div className="sidbar d-flex flex-column flex-shrink-0 " style={{ height: '92vh',overflowY:"scroll" }}>
            <div className='mt-3 mb-5' >
                        <img src={logo} alt='logo' className='sidebarLogo' style={{height:"40px",width:"150px"}} />
                    </div>

            <ul className="nav nav-pills flex-column mb-auto " >
              <li className="nav-item">
              <NavLink to="/home" className="nav-link" aria-current="page">
            <i className="bi bi-microsoft me-2"></i>
            Dashboard
           
          </NavLink>
              </li>
             
              <li className="nav-item mt-3">
                <NavLink to="/MyTeam" className="nav-link" aria-current="page">
                <i class="bi bi-microsoft-teams me-2"></i>
                     My Team
                </NavLink>
              </li>
                <li className="nav-item mt-3">
                <NavLink to="/DepositFund" className="nav-link" aria-current="page">
                <i class="bi bi-cash-stack me-2"></i>
                Deposit Funds
                </NavLink>
              </li>
                <li className="nav-item mt-3">
                <NavLink to="/WithdrawalHome" className="nav-link" aria-current="page">
                <i class="bi bi-cash-stack me-2"></i>
                Withdraw Funds
                </NavLink>
              </li>
              <li className="nav-item mt-3">
                <NavLink to="/P2P" className="nav-link" aria-current="page">
                <i class="bi bi-arrow-left-right me-2"></i>
                 P 2 P Transfer
                </NavLink>
              </li>
              <li className="nav-item mt-3">
                <NavLink to="/Transaction" className="nav-link" aria-current="page">
                <i class="bi bi-wallet2 me-2"></i>
                Transactions
                </NavLink>
              </li>
              <li className="nav-item mt-3">
                <NavLink to="/PurchasePaln" className="nav-link" aria-current="page">
                <i class="bi bi-receipt me-2"></i>
               Top-Up
                </NavLink>
              </li>
              <li className="nav-item mt-3">
                <NavLink to="/User" className="nav-link" aria-current="page">
                <i class="bi bi-person-circle me-2"></i>
                  Profile
                </NavLink>
              </li>

              <li className="nav-item mt-3">
                <NavLink to="/Income" className="nav-link" aria-current="page">
                <FaBitcoin size={20} />
                <span className='ms-2' >Incomes</span>
                </NavLink>
              </li>
              
              <li className="nav-item mt-3">
                <NavLink to="/MagicBoxDeposit" className="nav-link" aria-current="page">
                <FaBitcoin size={20} />
                <span className='ms-2' >Magic Box History</span>
                </NavLink>
              </li>
              <li className="nav-item mt-3">
                <NavLink to="/Direct" className="nav-link" aria-current="page">
                <LuServer size={20} />
                <span className='ms-2' >Direct Tree</span>
                </NavLink>
              </li>

              {/* <li className="nav-item mt-3">
                <NavLink to="/Nft" className="nav-link" aria-current="page">
                <BiDetail size={20} />
                <span className='ms-2' >NFT</span>
                </NavLink>
              </li> */}
            </ul>
            <li className="nav-item text-center  mt-2" style={{color:'black',listStyle:'none',background:'#fff',padding:'10px',borderRadius:'10px'}} onClick={()=>{handleLogout()}}>
                <div  >
                <i class="bi bi-box-arrow-right me-2 " style={{color:"black"}} ></i>
                  Logout
                </div>
              </li>

              

          </div>
          </div>
        </div>
      </div>


      {/* <div className="container mobileview">
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-12">
            <div> */}

            <Navbar bg="dark" variant="dark" className='mobileview' expand={false} fixed="top" style={{backgroundColor:"red"}}>
      <Container fluid>
        <Navbar.Toggle aria-controls="offcanvasNavbar" style={{ width: '50px' }} />
        <h5 className="text-white me-4  " style={{float:'right'}}>   MX Live Price $ 15 </h5>
        {/* <div className='usericn d-flex'>
       
          <NavLink to="/User" style={{ textDecoration: "none",border:"none" }}>
            <i className="bi bi-person-circle mb-2 mx-4" style={{ color: '#fff', fontSize: "30px" }}></i>
          </NavLink>
          <button class="button-3" role="button" style={{backgroundColor:'#fff',color:'#000'}}>
              <NavLink to="/PurchasePaln" style={{textDecoration:'none'}}> 
          <span class="text"> h</span>
              </NavLink>
          </button>
        </div> */}

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          style={{ width: '50%',backgroundColor:"#000425"}}
          className='sidbar'
        >
          <Offcanvas.Header  closeButton style={{display:'flex', justifyContent:'right', backgroundColor:'#fff'}} >
          
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="sidbar justify-content-start flex-grow-1 pe-3 offcanvas--menu" >
              <Link
                to="/home"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                  Dashboard
              </Link>
              <Link
                to="/MyTeam"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                      My Team
              </Link>
              <Link
                to="/P2P"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                P 2 P Transfer
              </Link>


              <Link
                to="/DepositFund"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                   Deposit Funds
              </Link>


              <Link
                to="/WithdrawalHome"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                Withdraw Funds
              </Link>
 

              <Link
                to="/Transaction"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
                Transactions
              </Link>

              <Link
                to="/PurchasePaln"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom border-light text-decoration-none"
                style={{color:'#fff'}}
              >
               Top-Up
              </Link>



              <Link
                to="/User"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
              >
                Profile
              </Link>

              <Link
                to="/Income"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
              >
                Incomes
              </Link>

              <Link
                to="/MagicBoxDeposit"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
              >
                Magic Box Deposits
              </Link>
              <Link
                to="/Direct"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
              >
               Direct Tree
              </Link>
              {/* <Link
                to ="/Nft"
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
              >
                Nft
              </Link> */}
              

            </Nav>

          </Offcanvas.Body>
          <Link
                
                spy={true}
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="p-3 border-bottom text-decoration-none"
                style={{color:'#fff'}}
                onClick={()=>{handleLogout()}}
              >
              <button>
                Logout
              </button>
              </Link>


        </Navbar.Offcanvas>
      </Container>
    </Navbar>
{/* 
            </div>
          </div>
        </div>
      </div>*/}
    </div> 
  )
}

export default SideBar