import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify'; 
import { Try } from '@mui/icons-material';
import '../App.css'


const P2PData = () => {

    const [first, setfirst] = useState(0);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [username, setUsername] = useState()
    const [amount, setamount] = useState()
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const [fund, setfund] = useState();
     const [UsdAmount, setUsdAmount] = useState();
     const [IncomeType, setIncomeType] = useState();
     const [Topup, setTopup] = useState([]);
     const [topo, settopo] = useState([]);


    const handleButtonClick = () => {
      setShowSecondForm(!showSecondForm);
    };

const P2Psend = async()=>{
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "username": username,
  "amount": amount
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/send/amount`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status == '1'){
    setUsername('')
    setamount('');
    toast(result.msg);

   }else{
    toast(result.error)
   }
    })
  .catch(error => console.log('error', error))
  .finally(()=>{
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  })
  ;
}


const P2Phistory = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/ptp/get`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result,"oooooo")
    if(result.status == 1){
      setdata(result.data)
    }
  })
  .catch(error => console.log('error', error));
}

const fundptpget = async()=>{ 

  try {
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get/ptp/data`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result,"pkkk")
  setfund(result.data)
})
  .catch(error => console.log('error', error));
  } catch (error) {
    console.log(error,"kjhgf");
  }
}



const P2PTopUp = async() =>{
  fundptpget();
  try {
    
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "amount":parseInt(UsdAmount) ,
  "txn_type": IncomeType,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/ptp/self-transfer`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      toast(result.msg) 
      fundptpget();
      setUsdAmount('')
      
      
    }else{
      toast(result.msg);
      setUsdAmount('') 
    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}


const TopupHistory = ()=>{
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/ptp/self-transfer-history`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      setTopup(result.data)
    }else{

    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}

const NameApi = () =>{
 
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get-user-name?username=${username}`, requestOptions)
  .then(response => response.json())
  .then(result =>{
    if(result.status == '1'){
      settopo(result.data)
    }
  })
  .catch(error => console.log('error', error));
}

useEffect(() => {
  fundptpget()
  P2Phistory()
  TopupHistory(); 
}, [])

  return (
  <div>
  <ToastContainer/>
     <section  >
    <div className='container crypton'>
    <div className='row'>
    <div className='col-lg-12'>
    <div className="you" style={{backgroundColor:'#262a43',width:'100%',padding:'10px', borderRadius:'8px'}}>
        <h5 className='my mx-3 '>P 2 P Transfer</h5>
    </div>
    </div>
    </div>    
    </div>
    </section>
    <div className='container-fluid mt-5' >
    <div className='row'>
   <div className='col-lg-12'>
   <div className='d-flex tabs-container' style={{borderRadius:'10px 10px 10px 0px', overflowX:'scroll',textWrap:'nowrap'}}>
  <h6
    style={{ cursor: 'pointer' }}
    onClick={() => setfirst(0)}
    className={first === 0 ? 'active' : ''}>

    <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>P2P Transfer</p>
  </h6>
  {/* <h6
    style={{ cursor: 'pointer' }}
    onClick={() => setfirst(1)}
    className={first === 1 ? 'active' : ''}
  >
    <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Topup Self </p>
  </h6> */}
  <h6
    style={{ cursor: 'pointer' }}
    onClick={() => setfirst(2)}
    className={first === 2 ? 'active' : ''}
  >
    <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}  onClick={()=>{P2Phistory()}} >Transfer History</p>
  </h6>
  <h6
    style={{ cursor: 'pointer' }}
    onClick={() => setfirst(3)}
    className={first === 3 ? 'active' : ''}
  >
    <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}} onClick={()=>{TopupHistory()}}>Topup History</p>
  </h6>
</div>


        {
        first==0?<>
               <div className=' mt-5'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                        <div class="card" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Fund Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.fund_wallet}</h5>
                            </div>
                        </div>
                        <div class="card mt-4" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Income Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.income_wallet}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div className='col-lg-6'>
 <div style={{ background: '#030c2b',borderRadius:"10px"}}>
<div style={{backgroundColor:"#3e3c5c",borderRadius:'8px'}}>
<p className='Transfer' style={{padding:'10px',color:'#fff',fontWeight:600,fontSize:"18px"}}>P2P Transfer </p>
</div>
        <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
         
          <label htmlFor="exampleFormControlInput1" className="f  d-flex align-items-center justify-content-between">
            Userid
           
          </label>
          <div style={{display:'flex'}}>
          <input
            type="text"
            value={username}
            onChange={(e)=>{setUsername(e.target.value)}}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter User ID"
          />

          <button className='btn btn-primary ms-3' onClick={()=>{NameApi()}} style={{width:'100px', textWrap:'nowrap'}}>Check</button>
          </div>
          <h5 className='text-white'>{topo}</h5>

          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount(USD)
          </label>
          <input
          value={amount}
          onChange={(e)=>{setamount(e.target.value)}}
            type="number"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />

          {/* <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select className="form-select mt-2" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> */}

          <button className="btn1 mt-4"  data-bs-toggle="modal" data-bs-target="#exampleModal120"  style={{cursor:'pointer'}}>
          {loading ? 'Loading...' : 'Procced '}
          </button>
        </div>
      )}
    </div>  
    </div>
</div>
                </div>
            </div>
            </div>

            <div class="modal fade" id="exampleModal120" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header"> 
      </div>
      <div class="modal-body">
        <div>
          <h5 className='text-center mb-4'>Confirm P2P Transaction?</h5>

          <div style={{display:'flex',justifyContent:'space-around'}}>
            <button className='btn btn-danger' data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            <button className='btn btn-primary' onClick={()=>{P2Psend()}}  data-bs-dismiss="modal" aria-label="Close" >Yes</button>
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>


        </>:
        first==1?<>
        <div className=' mt-5'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                        <div class="card" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Fund Wallet Balance </h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.fund_wallet}</h5>
                            </div>
                        </div>
                        <div class="card mt-4" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Income Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.income_wallet}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div className='col-lg-6'>
 <div style={{ background: '#030c2b',borderRadius:"10px"}}>
<div style={{backgroundColor:"#3e3c5c",borderRadius:'8px'}}>
<p className='Transfer' style={{padding:'10px',color:'#fff',fontWeight:600,fontSize:"18px"}}>Main 2 Fund Transfer  </p>
</div>
        <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">

          <label htmlFor="exampleFormControlInput2" className="f mt-1">
            Enter Amount
          </label>
          <input
          value={UsdAmount}
          onChange={(e)=>{setUsdAmount(e.target.value)}}
            type="number"
            className="form-control mt-2 mb-1"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />
          <p className='pt-1'><small style={{color:'#fff'}}>Platform charge 10% Applicable.</small></p>

          <select class="form-select py-2" aria-label="Default select example" onChange={(e)=>{setIncomeType(e.target.value)}}>
          <option selected>Select Wallet</option>
          <option value="income_to_fund">Income Wallet to Fund Wallet</option>
          {/* <option value='nft_income_to_nft_fund'>NFT Income Wallet to NFT Fund Wallet</option>  */}
        </select>






          <button className="btn1 mt-4" onClick={()=>{P2PTopUp()}} style={{cursor:'pointer'}}>
          {loading ? 'Loading...' : 'Procced'}
          </button>
        </div>
      )}
    </div>  
    </div>
</div>
                </div>
            </div>
            </div>

        </>:
        first==2?<>
        <div className='container-fluid' >
<div className='row mt-5'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive p-5">
        <div
          className="p-3 mangru"
          style={{
            background: '#332f59',
            borderRadius: '10px',
            height: '55vh',
            overflowY: 'scroll',
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col" className='text-nowrap'>From User</th>
                <th scope="col">To User</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody >
              {data?.map((res,index) => (
                <tr key={index}>
                <td className="p-3">{index + 1}</td>
                  <td>{res.from_user}</td>
                  <td>{res.to_user}</td>
                  <td>$ {res.amount}</td>
                  <td className='text-nowrap'>{res.created_at.split('T')[0].split('d')[0]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
</div>
</div>
        </>:
        
        first==3?<>
        <div className='container-fluid' >
<div className='row mt-5'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive p-5">
        <div
          className="p-3 mangru"
          style={{
            background: '#332f59',
            borderRadius: '10px',
            height: '55vh',
            overflowY: 'scroll',
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S.no </th>
                <th scope="col" className='text-nowrap'>User Name</th>
                <th scope="col">txn_type</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody >
              {Topup?.map((res,index) => (
                <tr key={index}>
                <td className="p-3">{index + 1}</td>
                  <td>{res.username}</td>
                  <td>{res.txn_type}</td>
                  <td>$ {res.amount}</td>
                  <td className='text-nowrap'>{res.created_at.split('T')[0].split('d')[0]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
</div>
</div>
        </>:null

        
    }
            </div>
    </div>
    </div>

    </div>

  )
}

export default  P2PData
    