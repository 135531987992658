import React from 'react'
import SideBar from './Sidebar'
import MagicBoxDepositData from './MagicBoxDepositData'

const MagicBoxDeposit = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-2 col-sm-12 p-0'>
            <SideBar/>
          </div>
          <div className='col-lg-10 col-sm-12'>
            <MagicBoxDepositData/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MagicBoxDeposit