import React, { useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [searchParams] = useSearchParams();
  const Navigate = useNavigate();
  const [email, setEmail] = useState(searchParams.get("username") || '');
  const [password, setPassword] = useState(searchParams.get("password") || '');
  const [loading, setLoading] = useState(false);
  // alert(email,'kjllkj')

  // const [password, setpassword] = useState();
  // const [username, setusername] = useState();

//   const handleLogin = async () => {
//     setLoading(true);
   
//     try {
//       const response = await fetch(`${apiurl}/user/login`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           username: email,
//           password: password,
//         }),
//       });

//       const result = await response.json();
// console.log(result);
//       if (result.status == '1') {
//         toast(result.msg);
//         localStorage.setItem("token", result.data.token);
        
//       } else {
//         toast.error(result.error);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

  const handleLogin = async() =>{
    setLoading(true);
    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  username: email,
  password: password,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/login`, requestOptions)
  .then(response => response.json())
  .then(result =>{ 
    console.log(result)
  
    if (result.status == '1') { 
      toast.success(result.msg);
      localStorage.setItem("token", result.token);
      Navigate('/home');
    }else{
      toast.error(result.error);
    }
  })
  .catch(error => console.log(error))
  .finally(()=>{setLoading(false)})
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <> 

    {/* <ToastContainer /> */}
      <div style={{ zIndex: 1 }}>
      </div>
      <div className="wrapper">
        <div className="login-box">
          <div>
            <h2>Login</h2>

            <div className="input-box">
              <span className="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              <input
              placeholder='Username'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <span className="icon">
                <ion-icon name="lock-closed"></ion-icon>
              </span>
              <input
              placeholder='Password'
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="remember-forgot">
              <label>
                <input type="checkbox" /> Remember me
              </label>
              <NavLink to="/ForgotPassword">Forgot Password?</NavLink>
            </div>

            <button onClick={handleLogin}>
              {loading ? 'Loading...' : 'Login'}
            </button> 
            
            <div className="register-link">
              <p>
                Don't have an account? <NavLink to="/SignUp">Register</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
