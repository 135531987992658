import React,{useState,useEffect} from 'react';
import '../component/Text.css';
import { apiurl } from '../env';
import { toast } from 'react-toastify';


const UserData = () => {
const [data, setdata] = useState([])
const [walletAddress, setWalletAddress] = useState(''); 
const [isEditing, setIsEditing] = useState(false); 
const [textToCopy, setTextToCopy] = useState();
const [Pass, setPass] = useState('');

const handleEditClick = () => {
  setIsEditing(true); 
};

const handleSaveClick = () => {
  setIsEditing(false);
  // Save(); 
}; 



// Function to handle the copy action
const handleCopyClick = () => {
  const link = document.getElementById("inp").value;

  // Add a small delay (e.g., 100 milliseconds) before copying
  setTimeout(() => {
      navigator.clipboard.writeText(link)
          .then(() => {
              console.log("Text successfully copied to clipboard");
              alert("Text successfully copied to clipboard")
          })
          .catch((err) => {
              console.error("Unable to copy text to clipboard", err);
          });
  }, 100);
}; 


const EmptyValue =()=>{
  setWalletAddress();
          setPass();
}




const profileApi = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
  
var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
  
    if(result.status == '1'){
      setdata(result.userData) 
      setWalletAddress(result.userData.wallet_address)
      setTextToCopy(window.location.protocol+'//'+window.location.hostname+'/SignUp?refid='+result.userData.username+"&name="+result.userData.name);
      console.log(result.userData,'sonu');
    }
    
  })
  .catch(error => console.log('error', error));
}



const Save = () =>{
  try {
    
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "wallet_address": walletAddress,
  "password": Pass
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/update-wallet-address`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      toast(result.msg)
      profileApi() 
      EmptyValue();
         
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    else{
      toast(result.error) 
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  })
  .catch(error => console.log('error', error));
    
  } catch (error) {
    
  }
}
useEffect(() => {
  profileApi()
}, [])



  return (
  <>
<section>
 <div className='container validation' >
 <div className='row mt-5'>
 <div className='col-lg-1 col-md-12 col-sm-12 '></div>
 <div className='col-lg-10 col-md-8 col-sm-12 pb-5 mb-5 py-3 '>
 <div   style={{backgroundColor:"#030c2b",borderRadius:'5px',padding:'20px'}} className='p    demomm'>
 <div style={{background:"linear-gradient(90deg, rgba(107,105,136,1) 0%, rgba(84,84,140,0.09016106442577032) 0%, rgba(151,127,158,1) 36%, rgba(81,88,89,1) 100%)",padding:'10px',boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',width:"100%",borderRadius:'5px'}}>
 <h4 style={{color:'#fff',textAlign:"center",fontWeight:700,fontSize:'28px'}}>User Details</h4>
 </div>


 <div className='row needs-validation  '>
  <div className='col-lg-6'>
  <div class="my-3">
    <label for="exampleInputEmail1" class="form-label">Name</label>
    <input type="text" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" value={data.name} readOnly/> 
  </div>
  </div>
  <div className='col-lg-6'>
  <div class="my-3">
    <label for="exampleInputEmail1" class="form-label">User id</label>
    <input type="text" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp"
    value={data.username} readOnly/> 
  </div>
  </div>
 </div>

 <div className='row'>
  <div className='col-lg-6'>





  <div class="my-3">
    <label for="exampleInputEmail1" class="form-label">Email</label>
    <input type="email" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" value={data.email} readOnly/> 
  </div>
  </div>
  {/* <div className='col-lg-6'>
  <div class="my-3">
    <label for="exampleInputEmail1" class="form-label">Rank</label>
    <input type="email" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" 
   value="0" readOnly/> 
  </div>
  </div> */}

<div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
      <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">  Wallet Address</label>
    <input type="email" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" value={walletAddress} disabled/> 
    <span className="text-white mt-2" style={{fontSize:"0.8rem",fontWeight:300}} >USDT BEP20 </span>
  </div>
      </div>

      <div className='col-lg-2 col-md-6 col-sm-12 mt-3'>
      <div class="mt-3">  
      <label for="exampleInputEmail1" class="form-label">  </label>
      <button className="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal">Edit</button> 
  </div>
      </div>
 </div>

 <div className='row'>
  <div className='col-lg-6'>
  <div class="u mt-4">
                            <button type='btn' onClick={handleCopyClick} 
                              style={{width:'auto',height:'auto', padding:'0', padding:'12px 14px', fontSize:'17px'}}> Copy Referral Id</button> 
                            
                            <input value={textToCopy} id="inp" type="text"   style={{visibility:"hidden"}}/>

                              
                            </div>
  </div>
      {/* <div className="col-lg-4">
        <div className="my-3">
          <label htmlFor="walletAddress" className="form-label">
            Wallet Address
          </label>
          <input
            type="text"
            className="form-control py-2"
            id="walletAddress"
            aria-describedby="emailHelp"
            value={walletAddress}

            // value={walletAddress,data.wallet_address}
            readOnly={!isEditing} // Set readOnly based on the edit mode
            onChange={(e) => setWalletAddress(e.target.value)}
          />
        </div>
      </div>
      
        <div className="col-lg-2">
        <div className="my-3 mt-4">
          {isEditing ? (
            <button className="btn btn-primary mt-4" onClick={handleSaveClick}>
              Save
            </button>
          ) : (
            <button className="btn btn-primary mt-4" onClick={handleEditClick}>
              Edit
            </button>
          )}
        </div>
      </div>*/}

      
    
    </div>
 </div>
 </div>
 </div>






 
 </div>
</section>




{/* edit button modal start */}

{/* <!-- Button trigger modal --> */}
 
{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Enter Wallet Address</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"> 

      <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label text-dark"> Wallet Address* <span className="" style={{fontSize:"0.9rem",fontWeight:300}} >(USDT BEP20)</span></label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setWalletAddress(e.target.value)}  /> 
  </div>

      <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label text-dark">  Password *</label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{setPass(e.target.value)}}/> 
  </div>


      </div>
      <div class="modal-footer"> 
        <button type="button" class="btn btn-success py-2" onClick={()=>{Save()}} data-bs-dismiss="modal">Update</button>
      </div>
    </div>
  </div>
</div>
      
  </>
  )
}

export default UserData