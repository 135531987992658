import React ,{useState,useEffect} from 'react'
import { apiurl } from '../env';
import'../App.css'


const IncomeData = () => {

const [data, setdata] = useState([])
const [data2, setdata2] = useState([]) 
const [selectedFilter, setSelectedFilter] = useState('');

const handleFilterChange = (e) => {
  const selectedValue = e.target.value;
  setSelectedFilter(selectedValue);
  // Call dataup with the selected filter value
  dataup(selectedValue);
};



const dataup = async(selectedValue)=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` ); 

  var requestOptions = {

    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  

  fetch(`${apiurl}/user/get/incomes?income_type=${selectedValue}`, requestOptions)
  .then(response => response.json())
   .then(result =>{ 
   if(result.status == 1){
    //  alert(result.msg,'data')
    setdata(result.data)
   }
})
  .catch(error => console.log('error', error));
} 



const Filtter = () =>{ 
  try {
    
var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` );

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/income-types`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status =='1'){
      setdata2(result.data)
      dataup(selectedFilter);
    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}
 


useEffect(() => {

   dataup();
   Filtter()
}, [])


  return (
    <>
     <section  >
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12 mt-4'>
    <div className='helloo' style={{backgroundColor:'#262a43',width:'100%',padding:'10px',borderRadius:'8px'}}>
        <h5 className='my mx-3'>Incomes</h5>
    </div>
    </div>
    </div>    
    </div>
</section>


<div className='container mt-5'>
  <div className='row'>
    <div className='col-lg-4 col-md-6 col-sm-12'>
    <select
            className="form-select py-2"
            aria-label="Default select example"
            onChange={handleFilterChange}
            value={selectedFilter}
          >
            <option value="" disabled>Select Filter</option>
            {data2?.map((res) => (
              <option key={res.value} value={res.value}>{res.name}</option>
            ))}
          </select>
    </div>
  </div>
</div>
<div className='container-fluid' >
<div className='row mt-3'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
       
      <div className="table-responsive ">
        <div
          className="p-3 income"
          style={{
            background: '#332f59',
            borderRadius: '10px',
            height: '50vh',
            overflowY: 'scroll',
          }}
        >
          <table className="table">
            <thead>
              <tr style={{textWrap:'nowrap'}}>
                <th scope="col">S.no</th>
                <th scope="col">Income Percentage</th>
                <th scope="col">Amount</th>
                <th scope="col">Income Type</th> 
                <th scope="col">Income From</th>
                <th scope="col">Date/time</th>
              </tr>
            </thead>
            <tbody >
              {data?.map((res,index) => (
                <tr>
                  <td >{index+1}</td>
                  <td>{res.percent}%</td>
                  <td> $ {res.amount}</td>
                  <td>{res.income_type}</td>
                  <td>{res.income_from}</td> 
                  <td> <span style={{textWrap:'nowrap'}}>{res.updated_at}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
</div>
</div>


    </>
  )
}

export default IncomeData