import React from "react";

function SalaryRewardTable({data, powerLeg, otherLeg}){
    const thresholds = [5, 20, 60, 135, 275, 530, 990, 1840, 3390, 6190, 11290, 20590, 37590, 68590, 143590];

    const rows = data.map((item,index)=>{
    const threshold = thresholds[item.level - 1];
    const isActive = powerLeg >= threshold && otherLeg >= threshold;
    const status = isActive ? 'Active' : 'Inactive';

    return (
      <tr key={index}>
        <td>{item.level}</td>
        <td>{item.name}</td>
        <td>{powerLeg} / {threshold}</td>
        <td>{otherLeg} / {threshold}</td>
        <td>{status}</td>
      </tr>
    );
    });
    return(
        <>
        {rows}
        </>
    );

}

export default SalaryRewardTable;