import React ,{useState,useEffect} from 'react'
import { apiurl } from '../env';
import '../App.css'
import { toast } from 'react-toastify';

const TransactionData = () => {
const [data, setdata] = useState([])
const [TrnsId, setTrnsId] = useState()


const trans = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get/user/transaction`, requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result)
    if(result.status == '1'){
    setdata(result.data);
    }
   })
  .catch(error => console.log('error', error));
}


const TransitionId = async(id)=>{
 
  try {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var raw = JSON.stringify({
  "txn_id": id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/info`, requestOptions)
  .then(response => response.json())
  .then(result =>{
    if(result.status == '1'){
      toast(result.msg);
      trans();
    }else{
      toast(result.msg);
      trans();
    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}

useEffect(() => {
  trans()
}, [])


  return (
    <>
     <section  >
    <div className='container mt-4   crypton10'  >
    <div className='row'>
    <div className='col-lg-12'>
    <div style={{backgroundColor:'#262a43',width:'100%',padding:'10px', borderRadius:'8px'}}>
        <h5 className='my mx-3'>Transaction     </h5>
    </div>
    </div>
    </div>    
    </div>
</section>
<div className='container-fluid' >
<div className='row mt-5'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      {/* <input
        type="text"
        placeholder="Search.."
        name="search2"
        className="mx-5 mt-3"
        style={{ padding: '10px', borderRadius: '10px', border: 'none' }}
      /> */}
      <div className="table-responsive ">
        <div
          className="p-3"
          style={{
            background: '#332f59',
            borderRadius: '10px',
            height: '75vh',
            overflowY: 'scroll',
          }}
        >
          <table className="table">
            <thead style={{textWrap:'nowrap'}}>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Crypto Amount</th>
                <th scope="col">Address</th>
                <th scope="col">Wallet</th>
                <th scope="col">Status</th>
                <th scope="col">Date/Time</th>
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody >
              {data?.map((res,index) => (
                <tr key={index}>
                  <td >{index +1}</td>
                  <td> {res.transaction_id}</td>
                  <td>{res.transaction_type}</td>
                  <td>$ {res.amount}</td>
                  <td>$ {res.crypto_amount}</td>
                  <td>{res.address}</td>
                  <td>{res.wallet}</td>
                  <td style={{color:"white"}}>{res.status == 0?<>Pending</>:res.status>0?<>Success</>:res.status<0?<>Faild</>:null}</td>
                  <td style={{textWrap:'nowrap'}}>{res.created_at.split('T')[0].split('d')[0]}</td>

                  <td><div><button className='btn btn-primary' 
                  onClick={()=>{TransitionId(res.transaction_id)}}>Refresh</button></div></td>


                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
</div>
</div>


    </>
  )
}

export default TransactionData