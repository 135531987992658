import React from 'react'
import MagicBox from './MagicBox'
import SideBar from '../Sidebar'

const Magic = () => {
  return (
    <div>

<div className='container-fluid'>
   <div className='row'>
   <div className='col-lg-2 col-md-2 p-0 ' style={{background:'#fff'}} >
           <SideBar/>
        </div>
        <div className='col-lg-10 col-md-10' >
            <MagicBox/>
        </div>
   </div>

   </div>
    </div>
  )
}

export default Magic