import React,{useState,useEffect} from 'react'
import code from '../Img/qr.png'
import { NavLink, json, useNavigate } from 'react-router-dom';
import { apiurl } from '../env';
import { toast, ToastContainer } from 'react-toastify';

const DepositFundData = () => {
  const Navigate = useNavigate();
  const [data, setdata] = useState()
  const [amount, setamount] = useState()
  const [amount99, setamount99] = useState()
  const [amount999, setamount999] = useState()
  const [first, setfirst] = useState(0);
  const [qrcode, setcode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [fund, setfund] = useState();
  const [Value33, setValue33] = useState();
  const [WalletAddress, setWalletAddress] = useState();
  const [deposit_wallet, setDepositWallet] = useState('fund_wallet');
  const [withdrawal_wallet, setWithdrawalWallet] = useState('income_wallet');
  
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    const inputElement = document.getElementById('qrInput');
    const spanElement = document.getElementById('copySpan');

    alert('Copy Successfully')
    if (inputElement && spanElement) {
      // Copy the value from the input to the span
      spanElement.innerText = inputElement.value;

      // Select and copy the text
      inputElement.select();
      document.execCommand('copy');

      // Update state to indicate that the text has been copied
      setCopied(true);

      // Reset after a short delay (optional)
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };


    const handleButtonClick = () => {
      setShowSecondForm(!showSecondForm);
    };

  const  datause =async()=>{

    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    
    var raw = JSON.stringify({
      "amount": amount,
      "deposit_wallet":deposit_wallet
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`${apiurl}/user/transactions`, requestOptions)
      .then(response => response.json())
      .then(async result => { 
      if(result.status == 1){
        toast(result.msg)
        await setdata(result)
        setTimeout(async()=>{
            await setcode(2)
        },1000)
      }
      else{
        toast(result.error)

      }
    })
      .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
  }



  const fundptpget = async()=>{
  
    try {
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${apiurl}/user/get/ptp/data`, requestOptions)
    .then(response => response.json())
    .then(result => {console.log(result,"pkkk")
    setfund(result.data);
    setWalletAddress(data.wallet_address)
  })
    .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error,"kjhgf");
    }
  }


  const Withdraw = async()=>{

    try { 
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "amount": amount999,
  "withdrawal_wallet":withdrawal_wallet
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/request-withdrawal`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.Backend_Error) {
      toast(result.Backend_Error);
    }
    if(result.status == '1'){
    toast(result.msg)
   
    setamount99()
    
    }
    else{
      toast(result.error)
      setamount99()
    }
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }
 
  useEffect(() => {
    fundptpget()
  }, [])
  

  return (
    <div style={{height:'97vh', overflowY:'scroll'}}>
    <ToastContainer/>
     <section  >
    <div className='container Deposit'>
    <div className='row'>
    <div className='col-lg-12'>
    <div style={{backgroundColor:'#262a43',width:'100%',padding:'10px', borderRadius:'10px'}}>
        <h5 className='my mx-3'>Deposit Funds</h5>
    </div>
    </div>
    </div>    
    </div>
</section>
<div className='row'>
  <div className='col-lg-12 col-sm-12 '>

 
       <div className='d-flex mt-3 ' style={{backgroundColor:'#262a43',width:'100%',  borderRadius:'10px 10px 10px 0px'}}>
        {/* <h6
          style={{ cursor: 'pointer' }}
          onClick={() => {setfirst(0);}}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}> Deposit</p>
        </h6> */}
        {/* <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Withdrawal  </p>

        </h6> */}
      </div>

      </div>
</div>


      {first ==0?<>
        <div className='container-fluid mt-4' >
<div style={{background:'#262a43',borderRadius:'10px',padding:"10px"}}>
<div className='row mt-5 px-3'>
<div className='col-lg-4 '>
<div class="card mb-3 p-3 text-center">
<h5 class="cp">Fund Wallet Balance </h5>
    <hr style={{color:'#fff'}}/>
    <h5 class="ca">${fund?.fund_wallet}</h5>
</div>
<div class="card mb-3 p-3 text-center">
<h5 class="cp">Income Wallet Balance </h5>
    <hr style={{color:'#fff'}}/>
    <h5 class="ca">${fund?.income_wallet}</h5>
</div>








</div>
<div className='col-lg-6 nabi'>
{qrcode == 1 ?
 <div style={{ background: '#030c2b',borderRadius:"10px"}}>
<div style={{backgroundColor:"#3e3c5c",borderRadius:'8px'}}>
<p style={{padding:'10px',color:'#fff',fontWeight:600,fontSize:"18px"}}>Deposit Add Funds</p>
</div>
        <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          {/* <label htmlFor="exampleFormControlInput1" className="f">
            Userid
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter User ID"
          /> */}

          <label htmlFor="exampleFormControlInput2" className="f  ">
            Enter Amount(USD)
          </label>
          <input
          value={amount}
          onChange={(e)=>{setamount(e.target.value)}}
            type="text"
            className="form-control mt-3 py-2"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />

<label htmlFor="exampleFormControlInput3" className="f mt-3">
 Select Deposit Wallet
          </label>
          {/* <select className="form-select mt-2 py-2" aria-label="Default select example"
          onChange={(e)=>{setDepositWallet(e.target.value)}}
          >
            <option selected>select type</option>
            
           {/* Mapping through the state array */}
                {/* {data?.map((res) => {
                        return ( */}
                          {/* <option value="fund_wallet">Fund Wallet</option> */}
                          {/* <option value="nft_fund_wallet">NFT Wallet</option> */}

                        {/* );
                      })} */}
          {/* </select> */}  

          <input type="text" class="form-control" id="exampleInputPassword1" value='fund_wallet' readOnly  />





          <label htmlFor="exampleFormControlInput3" className="f mt-3">
 Select Currency   
          </label>

          <select className="form-select mt-2 py-2" aria-label="Default select example"
          //  onChange={(event) => {
          //               const state = event.target.selectedOptions[0];
          //               setPlanId(state.getAttribute('id'));
          //               // setState_name(state.getAttribute('name'));
          //             }} 

            
          >
            <option selected>select Currency </option> 
                          <option value="USDT.BEP20">USDT BEP20</option> 
          </select>


          <div className="btn1 mt-4" style={{cursor:'pointer'}} onClick={()=>{datause()}}>
          {loading ? 'Loading...' : 'Procced'}
          </div>
        </div>
      )}

      {/* Second Form */}
      {showSecondForm && (
        <div className="mt-4 text-center mb-3">
          <p htmlFor="exampleFormControlInput4" className="f">
            Additional Field
          </p>
          <img src={code} />
          <p className='mt-3' style={{color:'#fff'}}>Currency in TRX: 987654567</p>
        <p className='mb-3' style={{color:'#fff',padding:"10px"}}>When the clicked, showSecondForm is s endered, allowing the second form to appear.</p>
        <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            Proceed
          </button>
        </div>
      )}
    </div>  
    </div>:
    
    <div style={{background:'#5a618c',borderRadius:'10px',padding:"10px",justifyContent:"center",alignItems:"center"}}>
            <div className="mt-4 text-center mb-3">
            <p htmlFor="exampleFormControlInput4" className="f">
           Currency :  USDT.BEP20
          </p>
          <p htmlFor="exampleFormControlInput4" className="f">
             Amount :  {data.amount}
          </p>
         
          <img src={data.link} alt='qr code' style={{marginLeft:"20"}}/>  

          <div className="input-group my-3">
      <input
        type="text"
        className="form-control"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        value={data.qraddress}
        readOnly
        id="qrInput"
      />
      <span
        className={`input-group-text ${copied ? 'text-success' : ''}`}
        id="basic-addon2"
        onClick={handleCopyClick}
        style={{ cursor: 'pointer' }}
      >
        {copied ? 'Copied!' : 'Copy '}
      </span>
    </div>
        {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>      
          </button> */}
        </div>
    </div>
    }
</div>
</div>
</div>
</div>
      </>:first==1?<>
      <div className='container-fluid mt-4'  >
<div style={{background:'#262a43',borderRadius:'10px',padding:"10px"}}>
<div className='row mt-4 px-3'>
<div className='col-lg-4 '>
<div class="card mb-3 p-3 text-center">
<h5 class="cp">Fund Wallet Balance </h5>
    <hr style={{color:'#fff'}}/>
    <h5 class="ca">${fund?.fund_wallet}</h5>
</div>
<div class="card mb-3 p-3 text-center" >
<h5 class="cp">Income Wallet Balance </h5>
    <hr style={{color:'#fff'}}/>
    <h5 class="ca">${fund?.income_wallet}</h5>
</div>
<div class="card mb-3 p-3 text-center" >
<h5 class="cp"> Magic Box Wallet </h5>
    <hr style={{color:'#fff'}}/>
    <h5 class="ca"></h5>




    <table class="table">
  <thead  >
    <tr> 
      <th style={{textAlign:'center'}}>Magic Wallet Balance</th>
      <td>${fund?.magic_wallet}</td> 
    </tr>
  </thead>
  <tbody>
    <tr> 
    
      <th style={{textAlign:'center'}}>Avelable to Withdraw</th> 
      <td>${fund?.magic_available}</td>
    </tr> 
  </tbody>
</table>








</div>
</div>
<div className='col-lg-6 nabi '>
 <div style={{ background: '#030c2b',borderRadius:"10px"}}>
<div style={{backgroundColor:"#3e3c5c",borderRadius:'8px'}}>
<p style={{padding:'10px',color:'#fff',fontWeight:600,fontSize:"18px"}}>Withdraw Funds</p>
</div>
        <div>
        <div className="px-4 pb-3">
          <label htmlFor="exampleFormControlInput2" className="f  ">
            Enter Amount
          </label>
          <input
          value={amount999}
          onChange={(e)=>{setamount999(e.target.value)}}
            type="text"
            className="form-control mt-3 py-2"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />
          <p className='pt-1'><small style={{color:'#fff'}}>Platform charge 15% Applicable.</small></p>
          <label htmlFor="exampleFormControlInput3" className="f mt-1">
            Select Withdrawal Wallet   
          </label>
              <select className="form-select mt-2 py-2" aria-label="Default select example"
              onChange={(e)=>{setWithdrawalWallet(e.target.value)}}
              >
                <option selected>select type</option>
                <option value="income_wallet" >Income Wallet</option>
                <option value="magic_wallet" >Magic Wallet</option>
                {/* <option value="nft_income_wallet" >NFT Wallet</option> */}
              </select>
          {/* <input type="text" class="form-control mt-2 py-2" id="exampleInputPassword1" value="income_wallet"  readOnly/> */}


{/* 
<label htmlFor="exampleFormControlInput2" className="f mt-2">
  Enter Wallet Address 
</label>
<input
value={fund?.wallet_address}
 
  type="text"
  className="form-control mt-3 py-2"
  id="exampleFormControlInput2"
  placeholder="Enter Wallet Address"
/> */}




<label htmlFor="exampleFormControlInput3" className="f mt-3">
 Select Currency
          </label>

          <select className="form-select mt-2 py-2" aria-label="Default select example"
          //  onChange={(event) => {
          //               const state = event.target.selectedOptions[0];
          //               setPlanId(state.getAttribute('id'));
          //               // setState_name(state.getAttribute('name'));
          //             }} 
          >
            <option selected>select Currency</option>
            
           {/* Mapping through the state array */}
                {/* {data?.map((res) => {
                        return ( */}
                          <option value="USDT.BEP20">USDT BEP20</option>
                          {/* <option >NFT Wallet</option> */}

                        {/* );
                      })} */}
          </select>

<div className="btn1 mt-4 py-2" onClick={()=>{Withdraw()}} style={{cursor:'pointer'}}>
   
{loading ? 'Loading...' : 'Procced'}  
</div>
</div>
    </div>  
    </div>
</div>
</div>
</div>
</div>
      </>:null

      }




    </div>
  )
}

export default DepositFundData