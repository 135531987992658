import React, { useState } from 'react';
import './Login.css';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';

function SignUp() {
  const [searchParams] = useSearchParams();

  const Navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [refid, setRefid] = useState(searchParams.get("refid") || '');
  const [refName, setrefName] = useState(searchParams.get("name") || '');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [apiName, setapiName] = useState("")
  const [apipass, setapipass] = useState("")
  // setRefid(reffid);

  const handleClose = () =>{ 
    
    Navigate("/");
    setShow(false)};
  const handleShow = () => setShow(true);

  console.log(window.location.pathname);
  const SignUp1 = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${apiurl}/user/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          refid: refid,
          name: name,
        }),
      });

      const result = await response.json();

      if (result.status == 1) {
        setapiName(result.username)
        setapipass(result.password)
        toast(result.msg);
        setName('')
        setRefid('')
        setrefName('')
        setEmail('')
        console.log(result);
        setTimeout(() => {
          handleShow()
         
        }, 1000); 
      } else {
        toast(result.error);
      } 
    } catch (error) {
      console.error('Error:', error).finally(()=>{setLoading(false)});
      toast.error('An error occurred while signing up.');
    }
  };

  return (
    <>
      <div style={{ zIndex: 1 }}>
        <ToastContainer />
      </div>
      <div className="wrapper p-2">
        <div className="login-box">
          <div>

          {/* modal start */}
          

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Authentication Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> <b>User Name : </b> {apiName} </p>
          <p> <b>Password : </b> {apipass} </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
          {/* modal end */}
            <h2>Sign Up</h2>
            <div className="input-box">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="Refid"
                value={refid}
                onChange={(e) => setRefid(e.target.value)}
              />
            </div>
              <p style={{color:"white "}} >{refName}</p>

            <button onClick={()=>{SignUp1()}} className="mb-4">
            {loading==false?"Sign up":"Loading...."}
            </button>
            <div className="register-link">
              <p>
                Don't have an account? <NavLink to="/">Login</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
