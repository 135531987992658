import React from 'react'
import Sidebar from '../Sidebar'
import P2PData from './P2PData'

const P2P = () => {
  return (
    <>
           <div className="animated-background">
            <div className='container-fulid' > 
            <div className='row' >
                <div className='col-lg-2 col-md-2' >
                   <Sidebar />
                </div>
                <div className='col-lg-10 col-md-12'>
                   <P2PData/>
                </div>
             
            </div>
        </div>
        </div>
    </>
  )
}

export default P2P
