// import React, { useEffect, useState } from 'react'
// import { apiurl } from '../env';
// import NFT from '../../src/Img/OIG.szyFIf_.jpg' 
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { json } from 'react-router-dom';
// import '../App.css'

// const Nftdata = () => {
//   const [Data, setData] = useState([])
//   const [Dataid, setDataid] = useState('')
// const [amount, setamount] = useState('')

//     const getData = async()=>{

//         try {
//             var myHeaders = new Headers();
//             myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
            
//             var requestOptions = {
//               method: 'GET',
//               headers: myHeaders,
//               redirect: 'follow'
//             };
            
//             fetch(`${apiurl}/user/nft/get`, requestOptions)
//               .then(response => response.json())
//               .then(result => {
//                 console.log(result);
//                 if(result.status == '1'){
//                     setData(result.data);
//                 }
//               })
//               .catch(error => console.log('error', error));            
//         } catch (error) {
            
//         }
//     }


//     const modalApi =() =>{
//       try {
          
//             var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");
// myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

// var raw = JSON.stringify({
//   "nft_id": JSON.stringify(Dataid),
//   "inv_amount": amount
// });

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow'
// };
 
// fetch(`${apiurl}/user/nft/bids`, requestOptions)
//   .then(response => response.json())
//   .then(async result => {console.log(result)
// if (result.status == '1') {
//  await setamount('') 
//  await getData()
//  console.log(setamount,'This is Api data')
// }

// toast(result.msg)
// })
//   .catch(error => console.log('error', error));
//         } catch (error) {
            
//         }
//     }

//     useEffect(() => {
//         getData();
//     }, [])
    
//   return (
//     <>

  

//         <div className='container  heit mt-5 pt-4'>
//             <div className='row'>
//                 <div className='col-lg-12 col-md-12 col-sm-12'>
//                 {/* <div style={{height:'46vh', overflowY:'scroll'}}>
//                 <table class="table">
//   <thead style={{textWrap:'nowrap'}}>
//     <tr>
//       <th scope="col">Sr.N</th>
//       <th scope="col">Name</th>
//       <th scope="col">Version</th>
//       <th scope="col">Amount</th>
//       <th scope="col">Collected Amount  </th>
//       <th scope="col">Date</th>
//       <th scope="col">action</th>
//     </tr>
//   </thead>
//   <tbody style={{  }}>

//   {
//     Data?.map((res,index)=>{
//         return(
//            <>
//            { res.status == 0 ? <> <tr>
//       <th >{index+1}</th>
//       <td>{res.name}</td>
//       <td>{res.version}</td>
//       <td>{res.amount}</td>
//       <td>{res.collected}</td>
//       <td>{new Date(res.created_at).toLocaleString()}</td>
//       <td><button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{setDataid(res.id)}}>Bid</button></td>
//     </tr></>:null  }
//            </>
//         )
//     })
//   }
    
//   </tbody>
// </table>
//                 </div> */}
//                 </div>
//             </div>




 
//         <div className='row' style={{height:'90vh', overflowY:'scroll'}}>
          

//         {
//           Data?.map((res,index)=>{
//             const percentage = (res.collected / res.amount) * 100;
//                  return(
//                <>
//                   {res.status == 0?<>
                  
//                     <div className='col-lg-4 col-md-6 col-sm-12'>
//           <div className="card mb-5 p-0">
//           <div className='card-header p-0 pt-1'>
//             <div>
//               <h4 className='text-white text-center'>NFT = {res.version}   <span className='ms-4'>{res.created_at.split('T')[0].split('d')[0]}</span></h4>

//               <h4 className='text-white text-center'>Name: {res.name}</h4>

//             </div>
//             <img src={NFT} alt='img' style={{width:'100%', borderRadius:'8px'}}/>
//           </div>
//             <div className='crad-body  p-3 ps-3 text-white'>
//               <h5>Price : {res.amount}  </h5>
//               <h5  >Biding : {res.collected} / {res.amount}
                
//               <div className="progress mt-3">
//       <div
//         className="progress-bar"
//         role="progressbar"
//         style={{ width: `${percentage}%` }}
//         aria-valuenow={percentage}
//         aria-valuemin="0"
//         aria-valuemax="100"
//       ></div>
//     </div> </h5>

//               <h5>Your Bid : {res.inv_amount}</h5>
//               <h5>Your Profit : {res.profit} </h5>
              
//               <div className='mt-4'>
//                 <button style={{fontWeight:'500', fontSize:'18px'}}>Bid Now More</button>
//               </div>
//             </div>
//             {/* <div className='crad-body  p-3 ps-3 text-white'>
//               <h5>Name : {res.name}  </h5>
//               <h5>Version :{res.version} </h5>
//               <h5>Your Amount : {res.amount}</h5>
//               <h5>Your Collected : {res.collected} </h5> 
              
//               <div className='mt-4'>
//                 <button style={{fontWeight:'500', fontSize:'18px'}}data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{setDataid(res.id)}}>Bid Now/More</button>
//               </div>
//             </div> */}
//           </div>  
//         </div> </>:null}
//         </>
//                  )
//           })
//         }
       
//         </div>
//         </div> 



//         {/* <!-- Button trigger modal --> */} 

// {/* <!-- Modal --> */}
// <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
//         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//       </div>
//       <div class="modal-body">
//       <div class="mb-3">
//     <label for="exampleInputPassword1" class="form-label text-dark">Amount</label>
//     <input type="number" class="form-control py-2" id="exampleInputPassword1" placeholder='Enter Amount' onChange={(e)=>{setamount(e.target.value)}} />
//   </div>
//       </div>
//       <div class="modal-footer"> 
//         <button type="button" class="btn btn-primary" onClick={modalApi} data-bs-dismiss="modal" >Bid</button>
//       </div>
//     </div>
//   </div>
// </div>



// {/* button  offcanvash start */}

//     </>
//   )
// }

// export default Nftdata









import React from 'react'

const Nftdata = () => {
  return (
    <div>

      <div className='container mt-5' style={{height:'100vh'}}>
        <div className='row'>
          <div className='col-lg-12 col-sm-12 mt-5'>
            <h1 className='text-white text-center'>Coming Soon</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nftdata