import React, { useEffect, useState } from 'react'
import "./Distribuite.css"
import Lottie from "lottie-react";
import { IoWalletOutline } from "react-icons/io5";
import R1 from "../Img/Animation - 1704936436322.json";
import R from "../Img/Animation - 1704937188059.json";
import { BsWallet2 } from "react-icons/bs";
import { VscDebugRestart } from "react-icons/vsc";
import { apiurl } from '../env';
import DirectProgress from '../component/DirectProgress'
const Directdata = () => {
  const [data, setdata] = useState(0)
  const [first,setfirst] = useState(0)
  const [store, setstore] = useState([]);

  const api = async()=>{ 
    try {
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${apiurl}/user/get/team/paln/purchase`, requestOptions)
    .then(response => response.json())
    .then(result => {console.log(result)
    if(result.status == 1) {
      setdata(result.data)
      console.log(result,'don')
    }
    
    })
    .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }


  const gethistory = async()=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

      fetch(`${apiurl}/user/upline-income-history`, requestOptions)
        .then(response => response.json())
        .then(result => {

          if(result.status == '1'){
            setstore(result.data);
          }

        })
        .catch(error => console.log('error', error));
          } catch (error) {
            
          }
        } 
    
  useEffect(()=>{
    api();
    gethistory();
  },[])

  return (
    <>


    <div className='container crypton10' style={{height:"89vh"}}>
    <div className='row'>
    <div className='col-lg-12 mt-2'>
    <div style={{backgroundColor:'#262a43',width:'100%',padding:'10px',borderRadius:'8px'}} >
        <h5 className='my mx-3' style={{ textAlign:"center",}} >UpLine  </h5>
    </div>
    </div>


    <div className='row'>
    <div className='d-flex mt-4 ' style={{backgroundColor:'#262a43',width:'100%',borderRadius:'8px'}} >
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => {setfirst(0);}}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}> Spil Income</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Spil History</p>

        </h6>
      </div> 
    </div>



    

{
  first ==0?<>
  <div className='card mt-4' style={{border:"2px solid rgba(255, 255, 255, 0.8)",borderRadius:"10px",height:"30vh",display:"flex",justifyContent:"center"}}  >
    {/* <div class="stepper-wrapper" style={{display:"flex",justifyContent:"center"}} >
       {data == 0 ? <>
        <div class="stepper-item"  >
    <div class="step-counter">1
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
        <div class="stepper-item"  >
    <div class="step-counter">2
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
        <div class="stepper-item"  >
    <div class="step-counter">3
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
       </>:null}
       
       {data == 1 ? <>
        <div class="stepper-item completed"  >
    <div class="step-counter">1
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >20%
    
    </div>

  </div>
        <div class="stepper-item"  >
    <div class="step-counter">2
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
        <div class="stepper-item"  >
    <div class="step-counter">3
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
       </>:null}


       {data == 2 ? <>
        <div class="stepper-item completed"  >
    <div class="step-counter">1
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >20%
    
    </div>

  </div>
        <div class="stepper-item completed"  >
    <div class="step-counter">2
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >20%
    
    </div>

  </div>
        <div class="stepper-item"  >
    <div class="step-counter">3
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >0%
    
    </div>

  </div>
       </>:null}


       {data == 3 ? <>
        <div class="stepper-item completed"  >
    <div class="step-counter">1
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >20%
    
    </div>

  </div>
        <div class="stepper-item completed"  >
    <div class="step-counter">2
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >20%
    
    </div>

  </div>
        <div class="stepper-item completed"  >
    <div class="step-counter">3
    </div>
    <div class="step-name" style={{color:"white" ,justifyContent:"center"}} >10%
    
    </div>

  </div>
  
       </>:null}
 
</div> */}

<div  style={{ width:'100%',overflowX:'scroll', backgroundColor:'#fff', borderRadius:'8px'}}> 
<DirectProgress/>
</div>
 
    </div>
    <div style={{display:"flex"}} >
         <div className='container mt-3' >
         <Lottie style={{height:"60vh",marginLeft:"45px",justifyItems:"flex-start"}}
                    className="Lottie w-50" // Add the class name here
                    animationData={R1} // replace with your animation data
                    loop
                    autoplay
                  />
         </div>
         <div className='container mt-3' >
         <Lottie style={{height:"60vh",marginLeft:"45px",justifyItems:"flex-start"}}
                    className="Lottie w-50" // Add the class name here
                    animationData={R} // replace with your animation data
                    loop
                    autoplay
                  />
         </div>
         </div>
  </>:first ==1?<>
  <div className='container-fluid' >
<div className='row mt-5'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive  ">
        <div
          className="p-3"
          style={{
            background: '#332f59',
            borderRadius: '10px', 
            overflowY: 'scroll',
          }}
        >

        <div style={{height:'50vh', overflowY:'scroll'}}>
          <table className="table">
            <thead>
              <tr style={{textWrap:'nowrap'}}>
                <th scope="col">S.no</th>
                <th scope="col" className='text-nowrap'>User Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Income Type</th>
                <th scope="col">Income From</th>
                <th scope="col">Percentage</th>
                <th scope="col" >Date </th>
                {/* <th scope="col" style={{textAlign:"center"}} >Withdrawal Amount</th> */}
              </tr>
            </thead>
            <tbody >
              {
                store?.map((res,index)=>{
                  return(
                    <tr>
                    <th scope="col">{index+1}</th>
                    <th scope="col">{res.username}</th>
                    <th scope="col">{res.amount}</th>
                    <th scope="col">{res.income_type}</th>
                    <th scope="col">{res.income_from}</th>
                    <th scope="col">{res.percent}%</th>
                    <th scope="col"  style={{textWrap:'nowrap'}}> {res.created_at.split('T')[0].split('d')[0]}</th>
                  </tr>
                  )
                })
              }
           
            </tbody>
          </table>
          </div>
       
        </div>
      </div>
    </div>
</div>
</div>
</div>

  </>:null
}


    

   
    </div>    
    </div>
</>
  )
}

export default Directdata