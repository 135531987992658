// BackgroundVideo.js
import React from 'react';
import '../Nokal.css'

const BackgroundVideo = () => {
  return (
    <div className="background-video-container">
      <video autoPlay loop muted className="background-video">
        <source src="https://assets.codepen.io/3364143/7btrrd.mp4" type="video/mp4" />
        {/* You can add additional source elements for different video formats (WebM, Ogg, etc.) */}
        Your browser does not support the video tag.
      </video>
      {/* Other content or components can be added here */}
    </div>
  );
};

export default BackgroundVideo;
