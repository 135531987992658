import React from 'react'
import Sidebar from '../Sidebar'
import MyTeamData from './MyteamData'

import Directdata from './Directdata'

const direct = () => {
  return (
    <>
           <div className="animated-background">
            <div className='container-fulid' > 
            <div className='row' >
                <div className='col-lg-2 col-md-2' >
                   <Sidebar />
                </div>
                <div className='col-lg-10 col-md-12'>
                   <Directdata/>
                </div>
             
            </div>
        </div>
        </div>
    </>
  )
}

export default  direct