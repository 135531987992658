import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css'

const PurchasePlandata = () => {
    const [first, setfirst] = useState(0);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [username, setUsername] = useState()
    const [amount, setamount] = useState(400)
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const [Palnid,setPlanId] = useState()
    const [history, sethistory] = useState([])
    const [fund, setfund] = useState();
    const [WithdrawalId, setWithdrawalId] = useState();



const palnget = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization",  `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/plans/get/byid`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status == 1){
    console.log(result.data ,'dara')
    sethistory(result.data)
  }
  })
  .catch(error => console.log('error', error));
}
const getPaln = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization",  `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/plans/get`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status == 1){
    console.log(result.formattedData ,'dara 2')
     setdata(result.formattedData)
  }
  })
  .catch(error => console.log('error', error));
}





const fundptpget = async()=>{
  
  try {
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get/ptp/data`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result,"pkkk")
  setfund(result.data)
})
  .catch(error => console.log('error', error));
  } catch (error) {
    console.log(error,"kjhgf");
  }
}


const Withdrawal = async() =>{
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "id": WithdrawalId
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/withdrawal`, requestOptions)
  .then(response => response.json())
  .then(result =>  {
    console.log(result)
    if(result.status == 1){
      toast(result.msg)
    }else{
      toast(result.msg)
    }
  })
  .catch(error => console.log('error', error));
}
const planpurches =async()=>{
  setLoading(true)
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var raw = JSON.stringify({
  // 'plan_id': Palnid, // Convert to string;;;bn nb  mb bjhgfugfh
  'plan_id': 2, // Convert to string;;;bn nb  mb bjhgfugfh
  "amount":  parseInt(amount) , 
});


var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/plans/purchase`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if(result.status == 1){
      palnget();
   toast(result.msg) 
   setamount(' ')

    }
    else{
   toast(result.error) 
   setamount(' ')
   
  }
 
  })
  .catch(error => console.log('error', error)).finally(()=>{
    fundptpget()
    setTimeout(()=>{
      setLoading(false)
      // window.location.reload()
    },1000)
  })
}

useEffect(() => {
  getPaln()
  fundptpget()
  palnget()
  }, [])

  return (
  <div className='klo'>
  <ToastContainer/>
     <section  >
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
    <div style={{backgroundColor:'#262a43',width:'100%',padding:'10px'}}>
        <h5 className='my mx-3'> Top-Up</h5>
    </div>
    </div>
    </div>    
    </div>
    </section>
    <div className='container-fluid mt-5' >
    <div className='row'>
   <div className='col-lg-12'>
   <div className='d-flex tabs-container'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => {setfirst(0); palnget()}}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}> Trade/Invest</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}onClick={()=>{palnget()}}>Trade History</p>

        </h6>
      </div>

        {
        first==0?<>
               <div className=' mt-5'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                        <div class="card" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Fund Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.fund_wallet}</h5>

                            </div>
                        </div>
                        <div class="card mt-4" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Income Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund?.income_wallet}</h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-6 col-sm-6 mt-3">
                    </div>
                    <div className='col-lg-6'>
 <div style={{ background: '#030c2b',borderRadius:"10px"}}>
<div style={{backgroundColor:"#3e3c5c",borderRadius:'8px'}}>
<p style={{padding:'10px',color:'#fff',fontWeight:600,fontSize:"18px"}}> Top-Up     </p>
</div>
        <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput1" className="f">
           Enter Amount
          </label>
          
          <input
            type="number"
            value="400"
            onChange={(e)=>{setamount(e.target.value)}}
            className="form-control py-2 my-2"
            id="exampleFormControlInput1"
            placeholder="Enter Amount"  readOnly
          />
          <span className='text-white '> + 100 $ Extra will be deducted for Magic Box
</span> <br/>

          {/* <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount(USD)
          </label>
          <input
          value={amount}
          onChange={(e)=>{setamount(e.target.value)}}
            type="number"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          /> */}

          {/* <label htmlFor="exampleFormControlInput3" className="f mt-3">
          Plan
          </label> */}

            
          {/* <input
            type="number" 
            className="form-control py-2"
            id="exampleFormControlInput1" 
            value=
          /> */}
          {/* <select className="form-select mt-2 py-2" aria-label="Default select example"
           onChange={(event) => {
                        const state = event.target.selectedOptions[0];
                        setPlanId(state.getAttribute('id'));
                        // setState_name(state.getAttribute('name'));
                      }} 
          >
            <option selected>Open this select Month</option>
            
            
                {data?.map((res) => {
                        return (
                          <option key={res.id} id={res.id} name={res['plan-name']}>
                          {res['plan-name']}
                          </option>
                        );
                      })}
          </select> */}

          <button onClick={planpurches} className="btn1 mt-4 "  style={{cursor:'pointer'}}>
          {loading ? 'Loading...' : 'Procced'}
          </button>
        </div>
      )}
    </div>  
    </div>
</div>
                </div>
            </div>
            </div>
        </>:first==1?<>
        <div className='container-fluid' >
<div className='row mt-5'>
<div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive p-5">
        <div
          className="p-3"
          style={{
            background: '#332f59',
            borderRadius: '10px', 
            overflowY: 'scroll',
          }}
        >

        <div style={{height:'50vh', overflowY:'scroll'}}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col" className='text-nowrap'>Plan Name</th>
                <th scope="col">Amount</th>
                <th scope="col" >Date</th>
                {/* <th scope="col" style={{textAlign:"center"}} >Withdrawal Amount</th> */}
              </tr>
            </thead>
            <tbody >
              {history?.map((res,index) => (
                <tr key={index}>
                <td className="p-3">{index + 1}</td>

                  <td >{res['plan-name']}</td>
                  <td>$ {res.amount}</td>
                  <td>{res.created_at.split('T')[0].split('d')[0]}</td>
                  {/* <td>
                    <div >
                      <button onClick={()=>{setWithdrawalId(res.id)}}  data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ backgroundColor: 'white', padding: '4px 30px', color: '#000', borderRadius: '8px', border: 'none',fontWeight:"300"}} >Redeem</button>
                    </div>
                  </td> */}
                </tr>

              ))}
            </tbody>
          </table>
          </div>
       
        </div>
      </div>
    </div>
</div>
</div>
</div>

        </>:null
    }
            </div>
    </div>
    </div>









    {/* <!-- Button trigger modal --> */}
 

{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">  
      </div>
      <div class="modal-body">
        <h4>Are you Sure you want to Redeem ? </h4>
        <div className='my-4' style={{display:'flex', justifyContent:'space-between'}}>
        <button type="button" class="btn btn-info" data-bs-dismiss="modal" aria-label="Close">No</button>
        <button type="button" class="btn btn-danger" onClick={Withdrawal} data-bs-dismiss="modal" aria-label="Close">yes</button>
        </div>
      </div>
      
    </div>
  </div>
</div>

    </div>

  )
}

export default  PurchasePlandata