import React, { useEffect, useState } from 'react'
import { apiurl } from '../env';
import '../App.css'
const MyTeamData = () => {
  const [l1, setl1] = useState([])
  const [l2, setl2] = useState([])
  const [l3, setl3] = useState([])
  const [l4, setl4] = useState([])
  const [l5, setl5] = useState([])
  const [l6, setl6] = useState([])
  const [l7, setl7] = useState([])
  const [l8, setl8] = useState([])
  const [l9, setl9] = useState([])
  const [l10, setl10] = useState([])
  const [l11, setl11] = useState([])
  const [l12, setl12] = useState([])
  const [l13, setl13] = useState([])
  const [l14, setl14] = useState([])
  const [l15, setl15] = useState([])
  const [l16, setl16] = useState([])
  const api = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${apiurl}/user/get/my/teem`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setl1(result.l1)
          setl2(result.l2)
          setl3(result.l3)
          setl4(result.l4)
          setl5(result.l5)
          setl6(result.l6)
          setl7(result.l7)
          setl8(result.l8)
          setl9(result.l9)
          setl10(result.l10)
          setl11(result.l11)
          setl12(result.l12)
          setl13(result.l13)
          setl14(result.l14)
          setl15(result.l15)
          setl16(result.l16)
          
        })
        .catch(error => console.log('error', error));
    } catch (error) {

    }
  }

  useEffect(() => {
    api();
  }, [])
  return (
    <>
      <section  >
        <div className='container crypton10'>
          <div className='row'>
            <div className='col-lg-12'>
              <div style={{ backgroundColor: '#262a43', width: '100%', padding: '10px', borderRadius:'8px' }}>
                <h5 className='my mx-3'>My Team</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container-fluid' style={{ height: '90vh', overflowY: 'scroll', }}>
        <div className='row mt-5'>
          <div className='col-lg-12 '>
            <div style={{ background: '#262a43', borderRadius: '10px' }}>
              <div class="accordion accordion-flush p-5" id="accordionFlushExample">
                <div class="accordion-item" >
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                     style={{borderRadius:'3px'}}>
                      Level #1 (Direct Referrals)
                    </button>
                  </h2>

                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>

                                  </tr>
                                </thead>

                                <tbody>
                                  {l1?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>

                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne" style={{borderRadius:'3px'}}>
                      Level #2
                    </button>
                  </h2>

                  <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>

                                  </tr>
                                </thead>

                                <tbody>
                                  {l2?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>

                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne" style={{borderRadius:'3px'}}>
                      Level #3
                    </button>
                  </h2>

                  <div id="flush-collapseOne2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>

                                  </tr>
                                </thead>

                                <tbody>
                                  {l3?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>

                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne" style={{borderRadius:'3px'}}>
                      Level #4
                    </button>
                  </h2>

                  <div id="flush-collapseOne3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">status</th>

                                  </tr>
                                </thead>

                                <tbody>
                                  {l4?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>

                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne" style={{borderRadius:'3px'}}>
                      Level #5
                    </button>
                  </h2>

                  <div id="flush-collapseOne4" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l5?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6" style={{borderRadius:'3px'}}>
                      Level #6
                    </button>
                  </h2>

                  <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l6?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading7">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7" style={{borderRadius:'3px'}}>
                      Level #7
                    </button>
                  </h2>

                  <div id="flush-collapse7" class="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l7?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 8 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading8">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8" style={{borderRadius:'3px'}}>
                      Level #8
                    </button>
                  </h2>

                  <div id="flush-collapse8" class="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l8?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 9 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading9">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9" style={{borderRadius:'3px'}}>
                      Level #9
                    </button>
                  </h2>

                  <div id="flush-collapse9" class="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l9?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 10 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading10">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10" style={{borderRadius:'3px'}}>
                      Level #10
                    </button>
                  </h2>

                  <div id="flush-collapse10" class="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l10?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 11 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading11">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11" style={{borderRadius:'3px'}}>
                      Level #11
                    </button>
                  </h2>

                  <div id="flush-collapse11" class="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l11?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 12 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading12">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12" style={{borderRadius:'3px'}}>
                      Level #12
                    </button>
                  </h2>

                  <div id="flush-collapse12" class="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l12?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 13 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading13">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13" style={{borderRadius:'3px'}}>
                      Level #13
                    </button>
                  </h2>

                  <div id="flush-collapse13" class="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l13?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 14 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading14">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14" style={{borderRadius:'3px'}}>
                      Level #14
                    </button>
                  </h2>

                  <div id="flush-collapse14" class="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l14?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 15 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading15">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15" style={{borderRadius:'3px'}}>
                      Level #15
                    </button>
                  </h2>

                  <div id="flush-collapse15" class="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l15?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* lvl 16 */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading16">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16" style={{borderRadius:'3px'}}>
                      Level #16
                    </button>
                  </h2>

                  <div id="flush-collapse16" class="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">User Name </th>
                                    <th scope="col">Activate Amount</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {l16?.map((data, i) => {
                                    return (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.username}</td>
                                        <td>$ {data.self_inv}</td>
                                        <td>{data.active ==1?<><p>Active</p></>:<p>Deactive</p>}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                


              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default MyTeamData