import React from 'react'
import Withdrawal from './Withdrawal'
import SideBar from '../Sidebar'

const WithdrawalHome = () => {
  return (
    <div>
           <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-2 p-0'>
                    <SideBar/>
                </div>
                <div className='col-lg-10 col-sm-12 '>
                   <Withdrawal/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WithdrawalHome