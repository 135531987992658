import React, { useEffect, useState } from "react";
import "../component/Text.css";
import Lottie from "lottie-react";
import animationData from "../Img/Animation - 1703578464129.json";
import { AiOutlinePlus } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { IoIosArrowUp } from "react-icons/io";
import { NavLink,  useNavigate } from "react-router-dom";
import SalaryRewardTable from './SalaryRewardTable';
// import { useHistory } from 'react-router-dom';

import { apiurl } from "../env";
import Carousel from "react-bootstrap/Carousel";
import { useMediaQuery } from 'react-responsive' 
import { toast, ToastContainer } from 'react-toastify';


const HomeData = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState();
  const [date, setdate] = useState(1);
  const [ref_USER, setref_USER] = useState();
  const [total_amount, settotal_amount] = useState(0)
  const [total_income, settotal_income] = useState(0)
  const [total_direct_income, setTotalDirectIncome] = useState(0)
  const[total_level_income,settotal_level_income]=useState(0)
  const [textToCopy, setTextToCopy] = useState();
  const [JackpotIncome, setJakpotIncome] = useState();
  const [ClubIncome, setClubIncome] = useState();
  const [TotalStekingincome,setTotalStekingincome] = useState();
  const [TotalUplineIncome,setTotalUplineIncome] = useState();
  const [totalWithdrawals,settotalWithdrawals] = useState();
  const [Monthly,setMonthly] = useState();
  const [Rank,setRank] = useState('');
  const [available_fund,setavailable_fund] = useState('');
  const [self_staking,setself_staking] = useState('');
  const [staking_roi, setstaking_roi] = useState('');
  const [direct_team, setdirect_team] = useState('');
  const [total_team, settotal_team] = useState('');
  const [direct_business, setdirect_business] = useState('');
  const [total_business, settotal_business] = useState('');
  const [Parents_Income , setParents_Income] = useState('');
  const [Reward_Income , setReward_Income] = useState('');
  const [today_income , settoday_income] = useState('');
  const [week_income , setweek_income] = useState('');
  const [month_income , setmonth_income] = useState('');
  const [balance_income , setbalance_income] = useState('');
  const [magic_wallet , setMagicWallet] = useState('');
  // const [current_month_jackpot , setcurrent_month_jackpot] = useState('');
  const [Name, setName] = useState([]); 
  const [EarningSlider, setEarningSlider] = useState([]); 
  const [imageSlider, setImageSlider] = useState([]); 
  const [magic_box_income, setmagic_box_income] = useState(''); 
  const [direct_balance_Income, setdirect_balance_Income] = useState(''); 
  const [TotalIncome, setTotalIncome] = useState(''); 
  const [maxCap, setMaxCap] = useState(0);
  const [directCap, setDirectCap] = useState(0);
  const [incomeCap, setIncomeCap] = useState(0); 
  const [salaryReward, setSalaryReward] = useState(0);

  const [powerLeg, setPowerLeg] = useState(0); 
  const [otherLeg, setOtherLeg] = useState(0); 
  const [highestLevelName, setHighestLevelName] = useState("None");
   
  const salaryData = [
    { level: 1, name:'Star'},
    { level: 2, name:'Bronze'},
    { level: 3, name:'Silver'},
    { level: 4, name:'Gold'},
    { level: 5, name:'Platinum'},
    { level: 6, name:'Topaz'},
    { level: 7, name:'Ruby'},
    { level: 8, name:'Diamond'},
    { level: 9, name:'Gold Diamond'},
    { level: 10, name:'Ruby Diamond'},
    { level: 11, name:'Blue Diamond'},
    { level: 12, name:'Black Diamond'},
    { level: 13, name:'Crown Diamond'},
    { level: 14, name:'Royal Diamond'},
    { level: 15, name:'Ambassador'},
  ];

  const api = async () => {

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${apiurl}/user/get`, requestOptions)
        .then((response) => {
          if(response.status === 503){ 

            setTimeout(() => {
              navigate('/ErrorPage');
            }, 1000);
          }
         return response.json()})
        .then((result) => {
         if(result.status =='1'){
          setTextToCopy(window.location.protocol+'//'+window.location.hostname+'/SignUp?refid='+result.userData.username+"&name="+result.userData.name);
          setName(result.top_ups_slider) 
          setEarningSlider(result.earnings_slider) 
          // setAmount2(result.top_ups_slider[0].amount) 
          setdata(result.userData);
          setdate(result.date);
          setref_USER(result.total_direct_team);
          settotal_amount(result.total_staking_amount)
          settotal_income(result.total_daily_income)
          setTotalDirectIncome(result.direct_income)
          settotal_level_income(result.level_income)
          setJakpotIncome(result.jackpot_income)
          setClubIncome(result.jackpot_amount)
          setTotalStekingincome(result.total_staking_income)
          setTotalUplineIncome(result.spil_income)
          settotalWithdrawals(result.total_withdrawal)
          // setjakpot(result.current_month_jackpot_club)
          setMonthly(result.current_month_highest_id)
          setRank(result.rank)
          setavailable_fund(result.available_fund)
          setself_staking(result.self_staking)
          setstaking_roi(result.staking_roi)
          setdirect_team(result.direct_team)
          settotal_team(result.total_team)
          settotal_business(result.total_business)
          setdirect_business(result.direct_business) 
          setParents_Income(result.parents_income) 
          setReward_Income(result.reward_income) 
          settoday_income(result.today_income) 
          setTotalIncome(result.total_income) 
          setweek_income(result.this_week_income) 
          setmonth_income(result.this_month_income) 
          setbalance_income(result.balance_income) 
          setMagicWallet(result.userData.magic_wallet) 
          // setcurrent_month_jackpot(result.current_month_jackpot);
          setImageSlider(result.slider_images)
          setmagic_box_income(result.magic_box_income)
          setSalaryReward(result.salary_reward_income)
          setdirect_balance_Income(result.direct_balance_income)
          setMaxCap(result.userData.self_inv*4)
          setDirectCap(result.direct_cap)
          setIncomeCap(result.income_cap)
          setPowerLeg(result.power_leg)
          setOtherLeg(result.other_leg)
          setHighestLevelName(result.reward_rank)         
         }
        })
        .catch((error) => console.log("error", error));
          } catch (error) {}
        };
  

  // Function to handle the copy action
  const handleCopyClick = () => {
    const link = document.getElementById("inp").value;

    // Add a small delay (e.g., 100 milliseconds) before copying
    setTimeout(() => {
        navigator.clipboard.writeText(link)
            .then(() => {
                console.log("Text successfully copied to clipboard");
                alert("Text successfully copied to clipboard")
            })
            .catch((err) => {
                console.error("Unable to copy text to clipboard", err);
            });
    }, 100);
}; 

const isMobile = useMediaQuery({
  query: "(max-width: 768px)",
});

  useEffect(() => {
    api();
  }, []);
  

  return (
    <>
    <ToastContainer/>
      {/* <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-10"></div>
            <div className="col-lg-2 d-flex justify-content-center align-items-center bg-transparent">
              <p
                onClick={handleCopyClick}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                <i className="bi bi-copy"></i>
              </p>
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle mb-2"
                    style={{ color: "#fff", float: "right", fontSize: "30px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      
      <div className="row d-flex align-items-center justify-content-around">
          <div className="col-lg-8 col-md-8 p-2" > 
          </div>
          <div className="col-lg-3 d-flex justify-content-center align-items-center bg-transparent dd"> 
              <div style={{ padding: "10px",display:'flex',justifyContent:'space-evenly',alignItems:'center',}}>
              {/* <NavLink to="/PurchasePaln" style={{textDecoration:'none'}}> 
              <button class="button-3" role="button" style={{backgroundColor:'#fff',color:'#000'}}>
          <span class="text">PlanPurches  </span>
          </button>
              </NavLink> */}
              <h5 className="text-white">   MX Live Price $ 15 </h5>
              <p className="mt-2"
                onClick={handleCopyClick}
                style={{ color: "#fff", cursor: "pointer",marginLeft:'20px' }}
              >
                {/* <i className="bi bi-copy mt-5"></i> */}
              </p>
                {/* <NavLink to="/User">
                  <i
                    className="bi bi-person-circle mb-2"
                    style={{ color: "#fff", float: "right", fontSize: "30px",marginLeft:'30px' }}
                  ></i>
                </NavLink> */}  
                
              </div>
            </div> 
        </div>

        <div className="col-lg-8 col-md-8 mt-5">
        <div className="mobileview   ">
            {/* <marquee
              direction="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff ",
              }}
            >
       MTFX Live Token price $ 15 
            </marquee> */}
             {/* <h5 className="text-white me-4 py-3" style={{float:'right'}}>   MX Live Price $ 15 </h5> */}
            </div>
          </div>


        
        
      <div className="container nkl h-10 hh" style={{ height: "80vh", overflowY: "scroll"}}
      > 
 


        <div className="row  nodejs">
          <div className="col-lg-12 col-sm-12 py-2"  >
          
          <marquee className="py-2"
              direction="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff ",
                fontWeight:'500',
                backgroundColor:'#11102b', borderRadius:'8px', display:'flex'
              }}
            >
              <div className="px-3" style={{display:'flex',  borderRadius:'8px', alignItems:'center'}}>
              {
                  Name?.map((res)=>{
                    return(
                           <>
                           <div className="mx-3" style={{display:'flex', border:'1px solid gray', borderRadius:'8px', alignItems:'center'}}>
                <h5 className="mx-3">"{res.name}" topup &nbsp; ${res.amount} </h5>
              </div> </>
                    )

                  })
              }
              </div>
              
            </marquee>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-12 col-sm-12 py-2"  >
          
          <marquee className="py-2"
              direction="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff ",
                fontWeight:'500',
                backgroundColor:'#11102b', borderRadius:'8px', display:'flex'
              }}
            >
              <div className="px-3" style={{display:'flex',  borderRadius:'8px', alignItems:'center'}}>
              {
                  EarningSlider?.map((res)=>{
                    return(
                           <>
                           <div className="mx-3" style={{display:'flex', border:'1px solid gray', borderRadius:'8px', alignItems:'center'}}>
                <h5 className="mx-3">"{res.name}" earned ${res.amount} {res.income_type} income </h5>
              </div> </>
                    )

                  })
              }
              </div>
              
            </marquee>
          </div>
        </div>

        <div className="container mt-3">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000"  >
  <div class="carousel-inner" style={{borderRadius:'8px'}}>
    
    {/* {
      imageSlider?.map((res)=>{
        return(
          <div class="carousel-item active">
          <img src={res.path} class="d-block w-100 hdImage" alt="..." style={{width:'100%',height:'50vh',objectFit:'cover'}}/>
        </div>
        )
      })
    } */}

{/* <Slider {...settings}>
        {imageSlider?.map((res, index) => (
          <div key={index}>
            <div className="p-2" >
            <img src={res.path} class="d-block w-100 hdImage" alt="..." style={{
            width: '100%',
            height:'45vh',
            objectFit: 'cover',
            display: 'block', 
            padding: '10px',
            position:'center' 
          }} />
          </div>
          </div>
        ))}
      </Slider> */}
  
    {/* <div class="carousel-item">
      <img src={babaji} class="d-block w-100" alt="..." style={{width:'100%',height:'30vh',objectFit:'cover'}}/>
    </div>
    <div class="carousel-item">
      <img src={babaji} class="d-block w-100" alt="... " style={{width:'100%',height:'30vh',objectFit:'cover'}}/>
    </div> */}
  </div>
</div>

<Carousel
          className="w-100"
          indicators={false}
          // controls={false}
        >
          {imageSlider && imageSlider.length > 0 ? (
            imageSlider
              .slice()
              .reverse()
              .map((data, value) =>
                  <Carousel.Item
                    interval={1000}
                    key={value}
                    style={{ height: isMobile?"200px":"45vh" }}
                  >
                    <img
                      key={value}
                      src={`${data.path}`}
                      className="d-block w-100"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center center",
                      }}
                      alt={`Slide ${value}`}
                    />
                  </Carousel.Item>
              )
          ) : (
            <h5 className="text-white">
              No Banners found !
            </h5>
          )}
        </Carousel>
          </div>
        </div> 
      </div>
        <div class="row mt-5">
          <div class="col-sm-12 col-md-12 col-lg-12">
   
               <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div className="card">
                    <div className="card-header border-0">
                      <h4 style={{color:"#fff", textAlign:'center'}}>My Profile Details</h4>
                    </div>
                    <div className="card-body">
                    <div style={{display:'flex', justifyContent:'center'}} >
                <table class="table" style={{width:'100%'}}>
            <thead>
              
            </thead>
            <tbody>
              <tr> 
                <td style={{width:'50%'}}>Name :</td>
                <td style={{width:'50%'}}>{data?.name}</td> 
              </tr>
              <tr> 
                <td>User Id :</td>
                <td>{data?.username}</td> 
              </tr>
              <tr> 
                <td>Email : </td>
                <td>{data?.email}</td> 
              </tr>
              <tr> 
                <td>Reward Rank : </td>
                <td>{highestLevelName}</td> 
              </tr> 
              <tr> 
                <td> <h5 style={{textWrap:'nowrap'}}> Referral Link :</h5> </td>
                <td><div class="u ">
                            <button type='btn' onClick={handleCopyClick} 
                              style={{width:'auto',height:'auto', padding:'0', padding:'4px 10px'}}> Copy Referral Id</button> 
                            
                            <input value={textToCopy} id="inp" type="text"   style={{visibility:"hidden"}}/>

                            </div></td> 
              </tr>
              
              <tr> 
                <td>Income / MaxCap : </td>
                <td>$ {incomeCap} / $ {directCap}</td> 
              </tr>
                
            </tbody>
          </table>
 
                
                </div>
                    </div>
                  </div>
                </div>



<div className="col-lg-6 col-md-6 col-sm-12 mb-3">

        <div className="card">
        <div className="card-header border-0">
                      <h4 style={{color:"#fff", textAlign:'center'}}>Wallet Options</h4>
                    </div>
         <div className="card-body ">
              
         <table class="table">
  <thead>
     
  </thead>
  <tbody>
    <tr> 
      <td>
          <NavLink to="/WithdrawalHome" >
          <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }} > 
                    Withdrawal
                    </button> 
                    </NavLink>
                    </td>

      <td>  <NavLink to="/P2P">
                    <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }}  > 
                P2P
                </button>
                </NavLink></td> 
      </tr>


    <tr> 
      <td>
                <NavLink to="/DepositFund">
  <button   style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }} > 
            Re-Topup
            </button> 
            </NavLink></td>

      <td> <NavLink to="/DepositFund">
          <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }} > 
                   Deposit
                </button> 
                </NavLink></td> 
    </tr>
    <tr> 
      <td>
                   <NavLink to="/PurchasePaln">                 
                   <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }}>Top-up</button> 
                   </NavLink></td>
                   {/* marker */}
      <td><NavLink to={`/MagicBox/${magic_wallet}`}  style={{textDecoration:'none'}}>
            <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }}  > 
              Magic Box
              </button>
        </NavLink></td>
    </tr>
  </tbody>
</table>
 
         </div>
        </div>
                </div>
                {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3"> */}

{/* <div className="card"  style={{border:'1px solid #fff'}}>
 <div className="card-body  ">
  
  <div className=" " style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
            <NavLink to="/Nft">
  <button   style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }} > 
            NFT
            </button> 
            </NavLink>

        <NavLink  style={{textDecoration:'none'}}>
            <button style={{backgroundColor:'transparent',color:'#fff',border:'1px solid #42b3f4' }}  > 
        Magic Box
        </button>
        </NavLink>
  </div>   
 </div>
</div> */}

{/* <div className="card mt-3"  style={{border:'1px solid #fff'}}>
 <div className="card-body p-0  ">
  
  <div className="row p-0">
            <div className="col-6 " style={{borderRight:'1px solid #fff'}}>
              <h6 className="p-2 text-white">Current Month Jakpot Club</h6>
              <h6 className="p-2 text-white">Current Month Highest ID</h6>
        <h5></h5>
            </div> 
            <div className="col-6 " style={{borderRight:'1px solid #fff'}}>
              <h4 className="p-2 text-white">$ {jakpot}</h4>
              <h4 className="p-2 text-white ps-4"> {Monthly}</h4>
        <h5></h5>
            </div> 
  </div>
 </div>
</div> */}
        
        {/* </div> */}
               </div>
          </div>
        </div>     

  <div className="row">
    <div className="col-lg-12 col-sm-12">
    <div style={{backgroundColor:"#000425", borderRadius:'8px'}}>
      <h3 className="p-3" style={{color:'#fff'}}>Incomes</h3>
    <table class="table" >
    <thead> 
    </thead>
    <tbody>
    <tr>
       
       <th style={{width: '50%'}}>Available Fund</th>
       {/* <td style={{width: '50%'}}>${data?.fund_wallet}</td>  */}
       <td style={{width: '50%'}}>${available_fund}</td> 
     </tr>
 
     <tr>
      <th scope="row">Self Staking/Investment </th>
      <td>$ {self_staking}</td> 
    </tr>

    <tr>
      <th scope="row"> Direct Team</th>
      <td>{direct_team}</td> 
    </tr>
    <tr>
      <th scope="row">Total Team</th>
      <td>{total_team}</td> 
    </tr>
    <tr>
      <th scope="row">Direct Business </th>
      <td>$ {direct_business}</td> 
    </tr>
    <tr>
      <th scope="row">Total Business </th>
      <td>$ {total_business}</td> 
    </tr>
    <tr>
      <th scope="row"> Direct Income</th>
      <td>$ {total_direct_income}</td> 
    </tr> 

    <tr>
      <th scope="row"> Level Income</th>
      <td>$ {total_level_income}</td> 
    </tr>

    <tr>
      <th scope="row">Spil Income</th>
      <td>$ {TotalUplineIncome}</td>  
    </tr>

    <tr>
      <th scope="row">Parents Income</th>
      <td>$ {Parents_Income}</td>  
    </tr>

    <tr>
      <th scope="row">Reward Income</th>
      <td>$ {Reward_Income}</td> 
    </tr>
    <tr>
      <th scope="row">Magic Box Income</th>
      <td>$ {magic_box_income}</td> 
    </tr>
    <tr>
      <th scope="row">Direct Balance Income</th>
      <td>$ {direct_balance_Income}</td> 
    </tr>

    <tr>
      <th scope="row">Today Income</th>
      <td>$ {today_income}</td> 
    </tr>

    <tr>
      <th scope="row">This week Income</th>
      <td>$ {week_income}</td> 
    </tr>
    
    <tr>
      <th scope="row">This Month Income</th>
      <td>$ {month_income}</td> 
    </tr>
    <tr>
      <th scope="row"> Total Withdrawal </th>
      <td>$ {totalWithdrawals}</td> 
    </tr>
    
    <tr>
      <th scope="row"> Balance Income</th>
      <td>$ {balance_income}</td> 
    </tr>

    <tr>
      <th scope="row"> Salary Reward Income</th>
      <td>$ {salaryReward}</td> 
    </tr>
    
    <tr>
      <th scope="row"> Total Income </th>
      <td>$ {TotalIncome}</td> 
    </tr>
  </tbody>
</table>
</div>
  </div>
</div>

<div className="row">
  <div className="col-lg-12 col-sm-12">
  <div style={{backgroundColor:"#000425", borderRadius:'8px'}}>
    <h3 className="p-3" style={{color:'#fff'}}>Salary Reward Status</h3>
  <table class="table" >
  <thead> 
  </thead>
  <tbody>
  <tr>
       <th>SN.</th>
       <th style={{width:'30%'}}>Level</th>
       <th>Power Leg</th>
       <th>Other Legs</th>
       <th>Status</th> 
    </tr>
    {/* SalaryRewardTable */}
    <SalaryRewardTable data={salaryData} powerLeg={powerLeg} otherLeg={otherLeg} />
  


     </tbody>
     </table>
     </div>
     </div>
     </div>


<div className='row mb-3'>
  {/* <div className='col-lg-1 col-sm-12  '></div> */}
   
  <div className="col-lg-4 col-sm-12 mt-3">
   <NavLink to="https://t.me/MTFXTOKEN2024" target="_blank"> <button className="btn btn-primary py-2">Join Telegram</button></NavLink>
  </div>
 </div>



<div className="row">
  
</div>


        

    
      </div>


      
    </>
  );
};

export default HomeData;
