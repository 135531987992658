import React,{ useEffect, useState } from 'react'
import { apiurl } from '../src/env';
import '../src/App.css'
import { toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MagicBoxDepositData = () => {

    
const [data , setData]= useState();
const [first, setfirst] = useState(0);
const [widtTable, setWidtTable] = useState([])
const [WithDrawHistory, setWithDrawHistory] = useState([])

const dataup = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` ); 

  var requestOptions = { 
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };   
  fetch(`${apiurl}/user/magic-box-deposits`, requestOptions)
  .then(response => response.json())
   .then(result =>{ 
   if(result.status == 1){   
    setData(result.data)
   }
})
  .catch(error => console.log('error', error));
} 

const trans = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${apiurl}/user/get/user/transaction`, requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result)
    if(result.status == '1'){
      setWidtTable(result.data);
    }
   })
  .catch(error => console.log('error', error));
}

const TransitionId = async(id)=>{
 
  try {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var raw = JSON.stringify({
  "txn_id": id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${apiurl}/user/info`, requestOptions)
  .then(response => response.json())
  .then(result =>{
    if(result.status == '1'){
      toast(result.msg);
      trans();
    }else{
      toast(result.msg);
      trans();
    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}


const WithdrawHistory =() =>{
  try {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

fetch(`${apiurl}/user/get/user/transaction?txn_wallet=magic_wallet&txn_type=withdrawal`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      setWithDrawHistory(result.data)
    }
  })
  .catch(error => console.log('error', error));
    
  } catch (error) {
    
  }
}

useEffect(() => {
  dataup();
  WithdrawHistory();
  trans();
}, [])

  return (
    <>
    <ToastContainer/>
        <div className='container mt-5' style={{height:'88vh',overflowY:'scroll', backgroundColor:'#12122e', borderRadius:'8px'}}> 
    <div className='row mt-4'>
      <div className='col-lg-12 col-sm-12'>
      <div className='d-flex tabs-container'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => {setfirst(0);}}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}> Deposit History</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Withdrawal History</p>

        </h6>
      </div>

      {
        first==0?(
          <>
          <div>
            <h2 className='mb-3'>Magic Box Deposit History</h2>
        </div>
      <table class="table">
  <thead>
    <tr>
      <th scope="col">S.no	</th> 
      <th scope="col">Amount</th> 
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    {
      data?.map((res,index)=>{
        return(
          <tr>
                  <td >{index+1}</td> 
                  <td> $ {res.amount}</td> 
                  <td> <span style={{textWrap:'nowrap'}}>{res.created_at.split('T')[0].split('d')[0]}</span></td>
                </tr>
        )
      })
    }
   
    
    
  </tbody>
</table>
          </>
        ):(
          <>
          <div>
            <h2 className='my-4'>Magic Box Withdrawal History</h2>
        </div>
      <table class="table">
      <thead style={{textWrap:'nowrap'}}>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Crypto Amount</th>
                <th scope="col">Address</th>
                <th scope="col">Wallet</th>
                <th scope="col">Status</th>
                <th scope="col">Date/Time</th>
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody >
              {WithDrawHistory?.map((res,index) => (
                <tr key={index}>
                  <td >{index +1}</td>
                  <td> {res.transaction_id}</td>
                  <td>{res.transaction_type}</td>
                  <td>$ {res.amount}</td>
                  <td>$ {res.crypto_amount}</td>
                  <td>{res.address}</td>
                  <td>{res.wallet}</td>
                  <td style={{color:"white"}}>{res.status == 0?<>Pending</>:res.status>0?<>Success</>:res.status<0?<>Faild</>:null}</td>
                  <td style={{textWrap:'nowrap'}}>{res.created_at.split('T')[0].split('d')[0]}</td>

                  <td><div><button className='btn btn-primary' 
                  onClick={()=>{TransitionId(res.transaction_id)}}>Refresh</button></div></td>


                </tr>
              ))}
            </tbody>
</table>
          </>
        )
      }

        
      </div>
    </div>
        </div>
    </>
  )
}

export default MagicBoxDepositData