import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiurl } from '../env';
import '../App.css'

const MagicBox = () => {
  const { paramValue } = useParams();

const [data , setData]= useState();

  const dataup = async()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` ); 
  
    var requestOptions = {
  
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }; 
  
    fetch(`${apiurl}/user/get/incomes?income_type=magic_box`, requestOptions)
    .then(response => response.json())
     .then(result =>{ 
     if(result.status == 1){
      //  alert(result.msg,'data')
      setData(result.data)
     }
  })
    .catch(error => console.log('error', error));
  } 

  useEffect(() => {
    dataup();
  }, [])
  
  return (
    <div>
        <div className='container mt-5 demo1' style={{height:'93vh',overflowY:'scroll', backgroundColor:'#0c153e', borderRadius:'8px'}}>
    <div className='row'>
      <div className='col-lg-12 col-sm-12 mt-5'>
      <h1 className='text-white text-center'>Magic Box</h1>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" style={{width:'50%'}}>Magic Wallet Balance</th>
          <th scope="col">$ {paramValue}</th>
        </tr>
      </thead>
    </table>




    <div className='row mt-4'>
      <div className='col-lg-12 col-sm-12'>
      <table class="table">
  <thead>
    <tr>
      <th scope="col">S.no	</th> 
      <th scope="col">Amount</th>
      <th scope="col">Income From	</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    {
      data?.map((res,index)=>{
        return(
          <tr>
                  <td >{index+1}</td> 
                  <td> $ {res.amount}</td>
                  <td>{res.income_from}</td> 
                  <td> <span style={{textWrap:'nowrap'}}>{res.created_at.split('T')[0].split('d')[0]}</span></td>
                </tr>
        )
      })
    }
   
    
    
  </tbody>
</table>
      </div>
    </div>
  </div>



   
  
  </div>
  )
}

export default MagicBox