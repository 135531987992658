import React, { useState, useEffect } from 'react';
  import "./Distribuite.css";
  import { apiurl } from '../env';
  import { Stepper,Step } from 'react-form-stepper';

  const StepperComponent = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [totalSteps, setTotalSteps] = useState();
    // const [(apiData), set(ApiData)] = useState(null);
    const [loading, setLoading] = useState(true);
    const [progress,setProgress] = useState(null);

    const palnactive = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch(`${apiurl}/user/get/team/paln/purchase`, requestOptions);
        const result = await response.json();

        if (result.status === '1') {
          // set(ApiData)(result.data);
          setTotalSteps(result.data.length);
          setActiveStep(1);
          setProgress(result.data);
        }
      } catch (error) {
        console.error('Error fetching API:', error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      palnactive();
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    const renderProgressBar = () => {
      const progressBar = [];
    
      for (let i = 1; i <=progress; i++) {
        const percentage = i % 3==0?10:20;
        progressBar.push(<Step key={i} label={`${percentage}%`} style={{color:'#fff'}}/>);
      }
    
      return progressBar;
    };
    

    return (
      <>
        <div className='container mt-5'>
          <div clas>
          <div>
          {loading ? (
          <p className='text-white' >Loading...</p>
        ) : (
          <div id="progress">
            <div id="progress-bar" ></div>
            <ul id="progress-num" style={{paddingLeft:'0%', marginLeft:'0%'}}>
    <Stepper activeStep={progress - 1} style={{ backgroundColor: '#fff',borderRadius:'10px'}}>
      {renderProgressBar()}
    </Stepper>
  </ul>

          </div>
        )}      
          </div>
          </div>
        </div>
      </>
    );
  };

  export default StepperComponent;