import React from 'react'
import UserData from './UserData'
import Sidebar from '../Sidebar'

const User = () => {
  return (
    <div className='container-fluid' > 
    <div className='row' >
        <div className='col-lg-2 col-md-2 p-0 ' style={{background:'#fff'}} >
           <Sidebar/>
        </div>

        <div className='col-lg-10 col-md-10' >
            <UserData/>
        </div>
    </div>
</div>
  )
}

export default User