import React from 'react'
import { NavLink,Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <div>
        <div className="container " style={{paddingTop:'300px', height:'100vh', backdropFilter:'blur(18px)'}}>
            <div className='row'>
                <div className="col-sm-12 col-md-12 col-lg-6 m-auto">
                    <h1 className='text-white text-center'>503  Service Unavailable.</h1> <br/> <h3 className='text-white text-center'>Maintenance mode is enabled, please try again later</h3>


                    <NavLink to="/home">
                      <button className='mt-5' style={{backgroundColor:'transparent', color:'#fff', borderRadius:'8px', padding:'8px 10px', border:'1px solid gray', fontSize:'20px' }}>Try Again</button>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ErrorPage