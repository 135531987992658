import React from 'react'
import Sidebar from '../Sidebar'
import Nftdata from './Nftdata'
function Nft() {
  return (
   <div className='container-fluid'>
   <div className='row'>
   <div className='col-lg-2 col-md-2 p-0 ' style={{background:'#fff'}} >
           <Sidebar/>
        </div>
        <div className='col-lg-10 col-md-10' >
            <Nftdata/>
        </div>
   </div>

   </div>
  )
}

export default Nft