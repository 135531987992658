import React ,{useEffect} from 'react'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import Home from '../src/component/Home';
import Login from '../src/component/Authentication/Login';
import SignUp from '../src/component/Authentication/SignUp';
import User from './component/User';
import Topbar from './component/Topbar';
import BackgroundVideo from './component/BackgroundVideo';
import ForgotPassword from './component/Authentication/Forgetpass';
import MyTeam from './component/MyTeam';
import MyTeamData from './component/MyteamData';
import Transaction from './component/Transaction';
import TransactionData from './component/TransactionData';
import DepositFund from './component/DepositFund';
import DepositFundData from './component/DepositFundData';
import P2P from './component/P2P';
import P2PData from './component/P2PData';
import PurchasePaln from './component/PurchasePaln';
import PurchasePlandata from './component/PurchasePlandata';
import Income from './component/Income';
import Direct from './component/Direct';
import Nft from './component/Nft';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Magic from './component/Magic'; 
import MagicBoxDeposit from './MagicBoxDeposit';
import MagicBoxDepositData from './MagicBoxDepositData'; 
import WithdrawalHome from './component/WithdrawalHome';
import ErrorPage from './component/ErrorPage';
function AllRoute() {

  const Navigate= useNavigate()
  useEffect(() => {
    
    if(!localStorage.getItem('token')){
      if(window.location.pathname !== '/SignUp'){
        Navigate("/")
      }
    }
  }, [])
  
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/ForgotPassword' element={<ForgotPassword/>} />
      <Route path='/home' element={<Home/>} />
      <Route path='/User' element={<User/>} />
      <Route path='/Topbar' element={<Topbar/>} />
      <Route path='/BackgroundVideo' element={<BackgroundVideo/>} />
      <Route path='/MyTeam' element={<MyTeam/>} />
      <Route path='/MyTeamData' element={<MyTeamData/>} />
      <Route path='/Transaction' element={<Transaction/>} />
      <Route path='/TransactionData' element={<TransactionData/>} />
      <Route path='/DepositFund' element={<DepositFund/>} />
      <Route path='/DepositFundData' element={<DepositFundData/>} />
      <Route path='/P2P' element={<P2P/>} />
      <Route path='/P2PData' element={<P2PData/>} />
      <Route path='/PurchasePaln' element={<PurchasePaln/>} />
      <Route path='/PurchasePalndata' element={<PurchasePlandata/>} />
      <Route path='/Income' element={<Income/>} />
      <Route path='/Direct' element={<Direct/>}/>
      <Route path='/Nft' element={<Nft/>} />
      <Route path='/MagicBoxDeposit' element={<MagicBoxDeposit/>} />
      <Route path='/MagicBoxDepositData' element={<MagicBoxDepositData/>} />
      <Route path='/MagicBox/:paramValue' element={<Magic/>} />
      <Route path='/WithdrawalHome' element={<WithdrawalHome/>} />
      <Route path='/ErrorPage' element={<ErrorPage/>} />
    
      </Routes>
    </>
  )
}

export default AllRoute
